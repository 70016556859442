import React from 'react';
import { Text, View, Image, TouchableOpacity, StyleSheet, ScrollView, Modal, findNodeHandle, UIManager } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';

import styleHelper from '../Styles/StyleHelper';
import ThemeStyles from '../Styles/ThemeStyles';

import RadioModifierGroup from './RadioModifierGroup';
import Modifier from './Modifier';
import IconPlus from './IconPlus';
import IconMinus from './IconMinus';

import API from '../Utils/API';
import Device from '../Utils/Device';
import Config from '../Utils/Config';
import Services from '../Utils/Services';

import StoreStore from '../Stores/StoreStore';
import OrderStore from '../Stores/OrderStore';

import CartActions from '../Actions/CartActions';

var RCTUIManager = UIManager;

const styles = StyleSheet.create({
    blurBg: {
        width: Device.width,
        height: Device.height,
        backgroundColor: 'rgba(0,0,0,0.8)',
    },
});

const productStyles = StyleSheet.create({
    popupContainer: {
        width: Device.width * 0.9,
        height: Device.height ?
                Device.height * 0.85 :
            Device.getSize(400),
        backgroundColor: '#FFF',
        paddingVertical: Device.getSize(10),
        marginTop: Device.getSize(20),
        marginBottom: Device.getSize(20),
        borderColor: '#000',
        borderWidth: 1,
        borderRadius: Device.getSize(10)
    },
    customBtn: {
        maxHeight: Device.getSize(30),
        marginTop: Device.getSize(10)
    },
    qtyInput: {
        width: Device.getSize(100)
    }
});

export default class ProductModal extends React.Component {
    constructor(props) {
        super(props);
        this.handleModifierScroll = this.handleModifierScroll.bind(this);
        this.addProduct = this.addProduct.bind(this);
        this.hideProduct = this.hideProduct.bind(this);
        this.renderProductQty = this.renderProductQty.bind(this);

        this.state = {
            modifierNodes: new Map(),
            productQty: 1,
            isOne: false,
            modifierY: 0,
        };
    }

    handleModifierScroll(event) {
        this.setState({
            modifierY: event.nativeEvent.contentOffset.y
        });
    }

    addProduct() {
        let is_oos = true;
        let is_timeout = true;
        const product = OrderStore.getSelectedProduct();

        // oos err msg
        if (product.available_for_order) {
            product.available_for_order = false;
            product.err_msg = 'We’re sorry, this item is unavailable right now!';
        }

        API.getCategoryById(product.category_ids[0].id, {
            success: re => {
                API.getProduct(product.id, {
                    success: (res) => {
                        // check oos
                        let storeId = StoreStore.getSelectedStore().id;
                        res.oos_shop_ids.forEach(shop_id => {
                            if (shop_id.id == storeId) {
                                is_oos = false;
                            }
                        });

                        // category available time
                        if (re.pos_category_day_ids.length > 0) {
                            let today = new Date();
                            let dayname = today.toLocaleDateString('en-us', { weekday: 'long' });
                            let hour = today.getHours();
                            let min = today.getMinutes();
                            let time = hour + (min / 60);
                            re.pos_category_day_ids.forEach(activeTime => {
                                if (activeTime.name == dayname.toLowerCase()) {
                                    if (activeTime.start_time <= time && activeTime.end_time > time) {
                                        is_timeout = false;
                                    }
                                }
                            });
                        } else {
                            is_timeout = false;
                        }

                        if (!is_oos && !is_timeout) {
                            product.available_for_order = true;
                            product.err_msg = '';
                        }

                        if (product.available_for_order || !Config.isProductionEnv) {
                            if (OrderStore.isSelectedModifierValid() === true) {
                                CartActions.addProductToCart(product, this.state.productQty);
                                CartActions.postOrder(OrderStore.getData());

                                this.hideProduct();
                            } else {
                                this.props.checkModifierValid();

                                try {
                                    let do_scroll = true;
                                    OrderStore.checkModifierGroupsValid().forEach((modifierValid, i) => {
                                        if (!modifierValid && do_scroll) {
                                            do_scroll = false;
                                            const modifierNode = this.state.modifierNodes.get(i);
                                            const position = findNodeHandle(modifierNode);
                                            RCTUIManager.measure(position, (fx, fy, w, h, px, py) => {
                                                this.setState({
                                                    modifierY: this.state.modifierY + py - 300
                                                });
                                                this.modifierScroll.scrollTo({ x: 0, y: this.state.modifierY + py - 300, animated: true });
                                            });
                                            // throw BreakException;
                                        }
                                    });
                                } catch (e) {
                                    // do nothing
                                }
                            }
                        } else {
                            if (product.err_msg) {
                                this.hideProduct(product.err_msg);
                            } else {
                                this.hideProduct('We’re sorry, this item is unavailable right now!');
                            }
                        }
                    },
                    error: (err) => {
                        Services.showAlertError(error);
                    }
                });
            },
            error: e => {
                Services.showAlertError(e);
            }
        })
    }

    hideProduct(msg = '') {
        this.setState({
            productQty: 1,
            isOne: false
        });
        this.props.onProductClose(msg);
    }

    renderProductQty(qty) {
        return (
            <View style={[styleHelper.flexRowSpaceBetween, productStyles.qtyInput]}>
                <IconMinus onPress={() => {
                    if (qty > 1 && this.state.productQty <= 1 && !this.state.isOne) {
                        this.setState({
                            productQty: qty - 1
                        })

                        // trick's key
                        if (this.state.productQty == 1) {
                            this.setState({
                                isOne: true
                            })
                        }
                    } else {
                        if (this.state.productQty > 1) {
                            this.setState({
                                productQty: this.state.productQty - 1
                            })
                        }
                    }
                }} />
                <View
                    style={[
                        styleHelper.inputBorderRadius,
                        styleHelper.bgWhite,
                        styleHelper.containerNumber2,
                    ]}>
                    <Text
                        style={[
                            styleHelper.fontRegular,
                            styleHelper.fontBlack,
                            styleHelper.font16,
                            styleHelper.textCenter,
                        ]}>
                        {this.state.productQty > 1 ? this.state.productQty : this.state.isOne ? 1 : qty > 0 ? qty : 1}
                    </Text>
                </View>
                <IconPlus onPress={() => {
                    if (qty > 0 && this.state.productQty <= 1 && !this.state.isOne) {
                        this.setState({
                            productQty: qty + 1
                        })
                    } else {
                        this.setState({
                            productQty: this.state.productQty + 1
                        })
                    }
                }} />
            </View>
        )
    }

    render() {
        let product = OrderStore.getSelectedProduct();
        let cart = require('../img/ic_cart_white.png');
        if (!product) {
            return <View />;
        }

        return (
            <Modal
                animationType="fade"
                transparent={true}
                visible={this.props.show}>

                <View style={[styleHelper.flex1, styles.blurBg, { alignItems: 'center' }]}>
                    <View style={[productStyles.popupContainer]}>

                        <View style={[styleHelper.borderBottomBlackTransparent, styleHelper.flexCenter, { paddingVertical: Device.getSize(10) }]}>
                            <Text style={[styleHelper.font16, styleHelper.fontHelveticaNeueBold, ThemeStyles.secondaryFontColor()]}>
                                Product
                            </Text>

                            <TouchableOpacity
                                onPress={() => this.hideProduct()}
                                style={[{ position: 'absolute', top: Device.getSize(5), right: Device.getSize(5) }]}>

                                <Ionicons name="close-circle" size={Device.getSize(20)} color={ThemeStyles.iconColor()} />
                                {/* <Image
                                    source={require('../Img/ic_close.png')}
                                    resizeMode={'contain'}
                                    style={[{ width: Device.getSize(20), height: Device.getSize(20) }]} /> */}
                            </TouchableOpacity>
                        </View>

                        <ScrollView
                            ref={(ref) => {
                                this.modifierScroll = ref
                            }}
                            scrollEventThrottle={0}
                            onScroll={this.handleModifierScroll}>
                            <Image
                                source={{
                                    uri: Services.getImageURL(product.id, 'product')
                                }}
                                resizeMode={'contain'}
                                style={[{ width: this.props.imgWidth, height: this.props.imgHeight }]} />

                            <View style={[{ paddingHorizontal: Device.getSize(10) }]}>
                                <View style={[styleHelper.flexRowSpaceBetween, { marginBottom: Device.getSize(10) }]}>
                                    <Text
                                        style={[
                                            styleHelper.fontHelveticaNeueBold,
                                            styleHelper.fontBlack,
                                            styleHelper.font14,
                                            styleHelper.containerVerticalNormal,
                                            { flex: 2 }
                                        ]}>
                                        {product.name}
                                    </Text>

                                    <View style={[{ flex: 1 }]}>
                                        <Text
                                            style={[
                                                styleHelper.fontHelveticaNeueBold,
                                                styleHelper.fontBlack,
                                                styleHelper.font14,
                                                styleHelper.containerVertical2,
                                                { textAlign: 'right' }
                                            ]}>
                                            {Services.formatPrice(this.props.productSubtotal)}
                                        </Text>

                                        <Text
                                            style={[
                                                styleHelper.fontHelveticaNeueRegular,
                                                styleHelper.fontXDark,
                                                styleHelper.font12,
                                                { textAlign: 'right' }
                                            ]}>
                                            Base price
                                        </Text>
                                    </View>
                                </View>

                                <ProductDescription description={product.description_sale} />

                                <View style={[{ paddingTop: Device.getSize(15) }]}>
                                    {product.product_variant_ids.length > 1 &&
                                        <RadioModifierGroup data={product.product_variant_ids} />
                                    }
                                    <Modifier
                                        modifierGroupsValid={this.props.modifierGroupsValid}
                                        modifierGroups={this.props.modifierGroups}
                                        modifierNodes={this.state.modifierNodes}
                                        toggleCustomModifiers={this.props.toggleCustomModifiers}
                                        onModifierChange={this.props.onModifierChange} />
                                </View>
                            </View>
                        </ScrollView>

                        <View style={[styleHelper.flexCenter, { paddingHorizontal: Device.getSize(5) }]}>
                            {this.renderProductQty(product.quantity)}

                            <TouchableOpacity
                                onPress={() => { this.addProduct() }}
                                activeOpacity={Config.activeOpacity}
                                style={[productStyles.customBtn, styleHelper.customBtn, styleHelper.containerTouchableBig, styleHelper.flexRow, styleHelper.flexCenter,
                                product.available_for_order && ThemeStyles.primaryBgColor(),
                                !product.available_for_order && styleHelper.bgLightGrey,
                                ]}>

                                <Image
                                    source={cart}
                                    style={styleHelper.verysmallIconSize}
                                    resizeMode={'contain'} />

                                <Text style={[styleHelper.fontHelveticaNeueBold, styleHelper.fontWhiteGold, styleHelper.font14, styleHelper.textCenter]}>
                                    {!product.cartID ? 'ADD TO CART' : 'UPDATE ITEM'} - {Services.formatPrice(this.props.productSubtotal * this.state.productQty)}
                                </Text>

                            </TouchableOpacity>
                        </View>

                    </View>
                </View>

            </Modal>
        )
    }
}

const ProductDescription = (props) => {
    return (
        <Text style={[styleHelper.fontHelveticaNeueRegular, styleHelper.font12]}>
            {props.description || ''}
        </Text>
    )
}
