import React from 'react';

import {ActivityIndicator} from 'react-native';
import Device from  '../Utils/Device';

const SmallSpinner = (props)=>{
    return (
        <ActivityIndicator
            size='small'
            color={props.color || 'black'}
            style={{marginTop: Device.getSize( props.noMargin ? 0:10), alignSelf: 'center'}} />
    )
}

export default SmallSpinner
