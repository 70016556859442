import React from 'react';
import {
  Text,
  View,
  Image,
  TextInput,
  TouchableOpacity,
  TouchableHighlight,
  ScrollView,
  KeyboardAvoidingView,
  StyleSheet,
  Modal,
} from 'react-native';

import * as Navigation from '../Routes/Routing';

import styleHelper from '../Styles/StyleHelper';
import ThemeStyles from '../Styles/ThemeStyles';
import Colors from '../Styles/Colors';

import OrderInfo from '../Components/OrderInfo';
import ImageBackground from '../Components/ImageBackground';
import ProductModal from '../Components/ProductModal';

import Services from '../Utils/Services';
import Device from '../Utils/Device';
import Config from '../Utils/Config';
import API from '../Utils/API';

import CartStore from '../Stores/CartStore';
import OrderStore from '../Stores/OrderStore';
import StoreStore from '../Stores/StoreStore';
import MenuStore from '../Stores/MenuStore';

import CartActions from '../Actions/CartActions';
import OrderActions from '../Actions/OrderActions';

const styles = StyleSheet.create({
  bottomSeparator: {
    marginBottom: Device.getSize(50),
  },
  suggestionItem: {
    borderColor: '#CCC',
    borderWidth: Device.getSize(1),
    borderStyle: 'solid',
    width: Device.getSize(200),
    height: Device.getSize(160),
    marginRight: Device.getSize(8),
    padding: Device.getSize(7),
  },
  spaceTop: {
    marginTop: Device.getSize(5)
  },
	menuContainer: {
		minWidth: Device.getSize(160),
		maxWidth: Device.getSize(160),
		minHeight: Device.getSize(225),
		flex: 1,
		marginLeft: Device.getSize(20),
		marginRight: Device.getSize(-20)
	},
	breadcum: {
		marginBottom: Device.getSize(5),
		marginTop: Device.getSize(5),
		marginLeft: Device.getSize(25),
		marginRight: Device.getSize(20),
		flex: 1,
		flexDirection: 'row',
		height: Device.getSize(40),
	},
  productImg: {
    width: Device.getSize(140),
    height: Device.getSize(120)
  },
  cartQtyBox: {
    paddingVertical: Device.getSize(5),
    paddingHorizontal: Device.getSize(5),
    width: Device.getSize(30),
    textAlign: 'center',
    backgroundColor: Colors.DarkOrange,
  },
  blurBg: {
    width: Device.width,
    height: Device.height,
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
});

export default class Cart extends React.Component {
    constructor(props) {
        super(props);
        this.onCartChange = this.onCartChange.bind(this);
        this.navigateMenu = this.navigateMenu.bind(this);
        this.renderTopNav = this.renderTopNav.bind(this);
        this.renderConfirmOrder = this.renderConfirmOrder.bind(this);
        this.onConfirmOrder = this.onConfirmOrder.bind(this);

        this.state = {
            refreshingData: false,
            products: CartStore.getServerProducts(),
            grandTotal: CartStore.getGrandTotal(),
            subtotal: CartStore.getSubtotal(),
            discount: CartStore.getDiscountAmount(),
            extraCharges: CartStore.getExtraCharge(),
            //   hasCard: PaymentStore.hasCard(),
            hasCard: false,
            numberValid: false,
            expiryValid: false,
            cvcValid: false,
            cardNumber: null,
            expiryMonth: null,
            expiryYear: null,
            cvc: null,
            spawnSlash: true,
            spawnDash1: true,
            spawnDash2: true,
            spawnDash3: true,
            spawnDash4: true,
            showPaymentCard: false,
        };
    }

    componentDidMount() {
        CartActions.postOrder(OrderStore.getData());
        CartStore.addChangeProductCart(this.onCartChange);
    }

    componentWillUnmount() {
        // CartStore.removeExpireCartListener(this.onCartExpire);
        // CartStore.removeSuccessfulPaymentListener(this.onSuccessfullyPay);
        CartStore.removeChangeProductCart(this.onCartChange);
    }

    onCartChange() {
        this.setState({
          grandTotal: CartStore.getGrandTotal(),
          subtotal: CartStore.getSubtotal(),
          discount: CartStore.getDiscountAmount(),
          extraCharges: CartStore.getExtraCharge(),
          products: CartStore.getServerProducts(),
        });

        // redirect to menu if empty cart
        if (this.state.products.length < 1) {
          this.props.navigation.navigate('Home');
        }
    }

    navigateMenu() {
      Navigation.navigate('Home')
    }

    getData(){
        let data = [];

        //Add products
        let products = CartStore.getDisplayProducts();
        products.forEach((product)=>{
            let orderProduct = [0, 0, { // 0,0 => create new record in odoo
              name: product.name,
              qty: product.quantity,
              price_unit: product.price,
              price_subtotal: 0,
              subtotal: 0,
              price_subtotal_incl: 0,
              modifier_line: []
            }];
            if(product.variant){
                orderProduct[2].product_id = product.variant.id;
            }
            product.modifiers.forEach((modifier)=>{
                orderProduct[2].modifier_line.push({
                  id: modifier.id,
                  qty: modifier.quantity,
                  product_id: modifier.product_id.id,
                  name: modifier.product_id.name,
                  price: modifier.price,
                  price_subtotal: 0,
                  subtotal: 0,
                  price_subtotal_incl: 0
                });
            })
            data.push(orderProduct);
        });

        return data;
    }

    onConfirmOrder() {
      Services.showSpinner();

      let store = OrderStore.getData();
      let cartId = new Date().getTime();
      this.amount = CartStore.getGrandTotal();
      this.amount = parseFloat(this.amount.toFixed(2));

      let param = {
        jsonrpc: '2.0',
        params: {
          token: StoreStore.getQRToken(),
          summary_printer_ip: StoreStore.getSummaryIp(),
          order: {
            state: 'draft',
            pricelist_id: parseInt(StoreStore.getPriceListId()),
            session_id: parseInt(StoreStore.getSessionId()),
            user_id: parseInt(StoreStore.getUserId()),
            pos_reference: 'Order ' + cartId,
            amount_tax: 0,
            amount_total: this.amount,
            amount_paid: 0,
            amount_return: 0,
            table_id: parseInt(store.tableId),
            table_ids: [[6, 0, [parseInt(store.tableId)]]], // 6,0 => replace all records in list
            lines: this.getData()
          }
        }
      };

      if(OrderStore.getType2() == "takeaway") {
        param.params.order.order_mode = "take_away";
      }

      API.createOrder(param, {
        success: (res)=>{
          console.log(res)
          if(res.result.id) {
            Navigation.navigate('Confirmation')
          }
        },
        error: (err)=>{
          console.log(err)
          Services.showAlertError(err.error_descrip);
        },
        complete: ()=>{
          Services.hideSpinner();
        }
      });
    }

    renderConfirmOrder() {
        return (
            <View
                style={[styleHelper.flexRow, styleHelper.flexCenter,
                  ThemeStyles.secondaryBgColor(),
                    {borderRadius: Device.getSize(5), marginTop: Device.getSize(10)}
                ]}>

                <TouchableOpacity
                    style={[styleHelper.containerNormal, styleHelper.flexCenter, styleHelper.flex1, styleHelper.flexRow]}
                    onPress={()=>{ this.onConfirmOrder() }}>
                    <Text
                        style={[styleHelper.fontWhite, styleHelper.font18, styleHelper.fontBold]}>
                        Confirm Order
                    </Text>
                </TouchableOpacity>

            </View>
        )
    }

    renderTopNav() {
      return(
        <View style={[styleHelper.flexRow, ThemeStyles.primaryBgColor(), {height: Device.getSize(50)}]}>
            <View style={[styleHelper.flex1, {marginVertical: Device.getSize(15), marginLeft: Device.getSize(10)}]}>
                <TouchableOpacity onPress={()=>{ this.navigateMenu(); }}>
                    <Text style={[styleHelper.fontWhite, styleHelper.font15, styleHelper.fontHelveticaNeueBold]}>
                        &lt;
                    </Text>
                </TouchableOpacity>
            </View>
  
            <View style={[styleHelper.flex1, styleHelper.flexCenter]}>
                <Text style={[styleHelper.fontWhite, styleHelper.font16, styleHelper.fontHelveticaNeueBold]}>
                    CART
                </Text>
            </View>
  
            <View style={[styleHelper.flex1]} />
        </View>
      )
    }
  
    render() {
        return (
            <ImageBackground>
                {this.renderTopNav()}

                <View style={[styleHelper.flex1, styleHelper.flexColumn, {padding: Device.getSize(10)}]}>
                    <OrderInfo />

                    <View style={[{flex: 1}]}>
                        <KeyboardAvoidingView behavior={'position'}>
                            <CartDetails
                              products={this.state.products} />
                        </KeyboardAvoidingView>
                    </View>

                    <View style={[ThemeStyles.primaryBgColor()]}>
                      <Subtotal
                        subtotal={this.state.subtotal} />

                      <Discount
                        discount={this.state.discount} />

                      <ExtraCharges
                        extraCharges={this.state.extraCharges} />

                      <GrandTotal
                        grandTotal={this.state.grandTotal} />
                    </View>

                    {this.renderConfirmOrder()}
                </View>
            </ImageBackground>
        );
    }
}

const CartDetails = (props) => {
  return (
      <View>
          <View style={[{height: Device.height * 0.55, padding: Device.getSize(5)}]}>
              <Products
                products={props.products} />
          </View>
      </View>
  );
}

class Products extends React.Component {
  constructor(props) {
    super(props);

    this.onVariantChange = this.onVariantChange.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.editProduct = this.editProduct.bind(this);
    this.applyTssCustomModifier = this.applyTssCustomModifier.bind(this);
    this.onProductClose = this.onProductClose.bind(this);
    this.checkModifierValid = this.checkModifierValid.bind(this);
    this.onModifierChange = this.onModifierChange.bind(this);
    this.toggleCustomModifiers = this.toggleCustomModifiers.bind(this);

    this.state = {
      showProduct: false,
      productSubtotal: OrderStore.getCurrentSubtotal(),
      modifierGroups: [],
      modifierGroupsValid: [],
      imgWidth: 0,
      imgHeight: 0,
    };
  }

  componentDidMount() {
    OrderStore.addChangeVariantListener(this.onVariantChange);
  }

  componentWillUnmount() {
    OrderStore.removeChangeVariantListener(this.onVariantChange);
  }

  onVariantChange() {
    let modifierGroups = this.applyTssCustomModifier();
    this.setState({
      productSubtotal: OrderStore.getCurrentSubtotal(),
      modifierGroups: modifierGroups
    });
  }

  // apply TSS custom modifier logic
  applyTssCustomModifier() {
    let modifierMaps = MenuStore.getModifierMap();
    let modifierGroups = OrderStore.getModifierGroups();
    let newModifierGroups = [];
    modifierGroups.forEach((modifierGroup)=>{
      modifierGroup.hide = false;
      modifierGroup.modifier1 = 0;

      // DB was designed for multi modifiers, but temporarily treat as single modifier
      // apply_to_show was currently unused bcos leak of time to do (urgently needed)
      let qty = 0;
      modifierMaps.forEach(modifierMap => {
        if(modifierGroup.id == modifierMap.modifier2 && modifierMap.apply_to_show) {
          qty = 0;
          // check if parent selected
          modifierGroups.forEach((mg)=>{
            if(mg.id == modifierMap.modifier1) {
              mg.product_modifier_ids.forEach(m => {
                qty += m.quantity;
              });
            }
          });

          if(qty < 1) {
            modifierGroup.hide = true; // hide if parent not selected
          } else {
            modifierGroup.hide = false; // show if parent selected
          }
          modifierGroup.modifier1 = modifierMap.modifier1;
        }
      });
      newModifierGroups.push(modifierGroup);
    });

    return newModifierGroups;
  }
  
  onProductClose(msg) {
    this.setState({
      showProduct: false,
      modifierGroupsValid: [true, true, true, true, true, true],
    });

    if (msg) {
      this.setState({
        showPopupMsg: true,
        msg: msg
      });
    }
  }

  checkModifierValid() {
    this.setState({
      modifierGroupsValid: OrderStore.checkModifierGroupsValid(),
    });
  }

  onModifierChange(modifierGroupsValid) {
    this.setState({
        modifierGroupsValid: modifierGroupsValid,
    });
  }

  toggleCustomModifiers(modifierGroup, hide) {
    let newMG = [];
    let parentModifier = 0;
    this.state.modifierGroups.forEach((mg) => {
        // recursive call for children
        if (parentModifier != 0) {
            this.state.modifierGroups.forEach((mg2) => {
                if (parentModifier == mg2.modifier1) {
                    parentModifier = 0;
                    this.toggleCustomModifiers({ id: mg2.id }, hide);
                }
            });
        }

        if (mg.id == modifierGroup.id) {
            if (hide) {
                parentModifier = mg.id;

                OrderActions.resetModifierGroup(mg);
            } else {
                mg.hide = hide;
            }
        }

        newMG.push(mg);
    });

    this.setState({
        modifierGroups: newMG
    });
  }

  removeProduct(cartID) {
    CartActions.removeProduct(cartID);
  }

  editProduct(cartID) {
    let product = CartStore.findProduct(cartID);
    OrderActions.selectProduct(product);

    let modifierValids = [];
    OrderStore.getModifierGroups().forEach((modifier, i)=>{
      modifierValids.push(true);
    });

    let modifierGroups = this.applyTssCustomModifier();
    
    Image.getSize(Services.getImageURL(product.id, 'product'), (imageWidth, imageHeight) => {
      let scaledImage = Services.getImageScaledSize(imageWidth, imageHeight, Device.width * 0.9);
      this.setState({
        showProduct: true,
        productSubtotal: OrderStore.getCurrentSubtotal(),
        modifierGroups: modifierGroups,
        modifierGroupsValid: modifierValids,
        imgWidth: scaledImage.width,
        imgHeight: scaledImage.height
      })
    }, () => {
      this.setState({
        showProduct: true,
        productSubtotal: OrderStore.getCurrentSubtotal(),
        modifierGroups: modifierGroups,
        modifierGroupsValid: modifierValids,
      });
    });
  }

  render() {
    return (
      <View>
        <ProductBox
          show={this.state.showProduct}
          imgWidth={this.state.imgWidth}
          imgHeight={this.state.imgHeight}
          productSubtotal={this.state.productSubtotal}
          modifierGroups={this.state.modifierGroups}
          modifierGroupsValid={this.state.modifierGroupsValid}
          checkModifierValid={this.checkModifierValid}
          onModifierChange={this.onModifierChange}
          toggleCustomModifiers={this.toggleCustomModifiers}
          onProductClose={this.onProductClose} />

        {/* nestedScrollEnabled={true} */}
        <ScrollView keyboardShouldPersistTaps="always" style={[{height: Device.height * 0.5}]}>
          {this.props.products.map((product, key) => (
            <View
              key={key}
              style={[
                styleHelper.containerVeryTiny,
              ]}>
              <View
                style={[
                  styleHelper.flexRowSpaceBetweenAlignTop,
                  styleHelper.containerVerticalSmall,
                ]}>
                <Text
                  style={[
                    styleHelper.flex1,
                    styleHelper.fontRegular,
                    styleHelper.font16,
                    styleHelper.fontBlack,
                  ]}>
                  {product.quantity}x {product.productName}
                </Text>

                <Text
                  style={[
                    styleHelper.fontRegular,
                    styleHelper.font16,
                    styleHelper.fontBlack,
                  ]}>
                    <Text>{Services.formatPrice(product.productPrice * product.quantity)}</Text>
                </Text>
              </View>

              <ProductVariant product={product} />

              {product.modifiers.map((modifier, _key) => (
                <View
                  key={_key}
                  style={[
                    styleHelper.flexRowSpaceBetweenAlignTop,
                    styleHelper.containerVeryXTiny,
                  ]}>
                  <Text
                    style={[
                      styleHelper.flex1,
                      styleHelper.fontRegular,
                      styleHelper.font12,
                      styleHelper.fontBlack,
                    ]}>
                    {product.quantity * modifier.quantity}x {modifier.modifierName}
                  </Text>

                  <Text
                    style={[
                      styleHelper.fontRegular,
                      styleHelper.font12,
                      styleHelper.fontBlack,
                    ]}>
                    {Services.formatPrice(modifier.price * modifier.quantity * product.quantity)}
                  </Text>
                </View>
              ))}

              <View
                style={[
                  styleHelper.flexJustifyEnd,
                  styleHelper.flexRow,
                  styleHelper.alignSelfStretch,
                  styleHelper.containerVerticalSmall,
                ]}>

                <TouchableOpacity
                  activeOpacity={Config.activeOpacity}
                  onPress={() => this.editProduct(product.cartID)}
                  style={[ThemeStyles.productBtn()]}>
                  <Text style={[ThemeStyles.secondaryFontColor(), styleHelper.fontRegular, styleHelper.font14]}>
                    Edit
                  </Text>
                </TouchableOpacity>

                <View style={styleHelper.containerHorizontalSmall} />

                <TouchableOpacity
                  activeOpacity={Config.activeOpacity}
                  onPress={() => this.removeProduct(product.cartID)}
                  style={[ThemeStyles.productBtn()]}>
                  <Text style={[ThemeStyles.secondaryFontColor(), styleHelper.fontRegular, styleHelper.font14]}>
                    Remove
                  </Text>
                </TouchableOpacity>

              </View>
            </View>
          ))}
        </ScrollView>
      </View>
    );
  }
}

const Subtotal = (props) => {
  if (!props.subtotal) {
    return <View />;
  }

  return (
    <View
      style={[
        styleHelper.customContainerNormal,
        styleHelper.flexRowSpaceBetween,
      ]}>
      <Text
        style={[
          styleHelper.font14,
          styleHelper.fontWhite,
          styleHelper.fontBold,
        ]}>
        Subtotal
      </Text>

      <Text
        style={[
          styleHelper.fontWhite,
          styleHelper.font14,
          styleHelper.fontBold,
        ]}>
        {Services.formatPrice(props.subtotal)}
      </Text>
    </View>
  );
}

const Discount = (props) => {
  if (!props.discount || props.discount == 0) {
    return <View />;
  }

  return (
    <View
      style={[
        styleHelper.customContainerNormal,
        styleHelper.flexRowSpaceBetween,
      ]}>
      <Text
        style={[
          styleHelper.font14,
          styleHelper.fontWhite,
          styleHelper.fontBold,
        ]}>
        Discount
      </Text>

      <Text
        style={[
          styleHelper.fontWhite,
          styleHelper.font14,
          styleHelper.fontBold,
        ]}>
        {Services.formatPrice(props.discount)}
      </Text>
    </View>
  );
}

const ExtraCharges = (props) => {
    if(!props.extraCharges) {
      return <View />
    }

    if (props.extraCharges.length === 0) {
      return <View />;
    }

    return (
      <View>
        {props.extraCharges.map((extraCharge, k) => (
          extraCharge.amount > 0 &&
          <View
            key={k}
            style={[
              styleHelper.customContainerNormal,
              styleHelper.flexRowSpaceBetween,
            ]}>
            <View style={styleHelper.flexRowSpaceBetween}>
              {extraCharge.percentage && (
                <Text
                  style={[
                    styleHelper.font12,
                    styleHelper.fontWhite,
                    styleHelper.fontRegular,
                    styleHelper.containerHorizontalSmall,
                  ]}>
                  {extraCharge.percentage}%
                </Text>
              )}
              <Text
                style={[
                  styleHelper.font14,
                  styleHelper.fontWhite,
                  styleHelper.fontRegular,
                ]}>
                {extraCharge.string}
              </Text>
            </View>

            <Text
              style={[
                styleHelper.fontWhite,
                styleHelper.font14,
                styleHelper.fontRegular,
              ]}>
              {Services.formatPrice(extraCharge.amount)}
            </Text>
          </View>
        ))}
      </View>
    )
}

const GrandTotal = (props) => {
  if(!props.grandTotal) {
    return <View />
  }

  return (
    <View
      style={[
        styleHelper.customContainerNormal,
        styleHelper.flexRowSpaceBetween,
      ]}>
      <Text
        style={[
          styleHelper.font14,
          styleHelper.fontWhite,
          styleHelper.fontRegular,
        ]}>
        Grand Total
      </Text>

      <Text
        style={[
          styleHelper.fontWhite,
          styleHelper.font14,
          styleHelper.fontBold,
        ]}>
        {Services.formatPrice(props.grandTotal)}
      </Text>
    </View>
  );
}

const ProductVariant = props => {
  if (props.product.product_variant_ids) {
    return (
      <View style={[styleHelper.flexRowSpaceBetweenAlignTop, styleHelper.containerVeryXTiny]}>
        <Text
          style={[
            styleHelper.flex1,
            styleHelper.fontRegular,
            styleHelper.font12,
            styleHelper.fontDark,
          ]}>
          {props.product.attribute_value_ids[0].name}
        </Text>
        {/* <Text
          style={[
            styleHelper.fontRegular,
            styleHelper.font12,
            styleHelper.fontDark,
          ]}>
          {Services.formatPrice(props.product.variantPrice)}
        </Text> */}
      </View>
    );
  }
  return null;
};

const ProductBox = (props) => {
  if (!props.show) {
    return <View />
  }

  return (
    <ProductModal
      show={props.show}
      imgWidth={props.imgWidth}
      imgHeight={props.imgHeight}
      productSubtotal={props.productSubtotal}
      modifierGroups={props.modifierGroups}
      modifierGroupsValid={props.modifierGroupsValid}
      checkModifierValid={props.checkModifierValid}
      onModifierChange={props.onModifierChange}
      toggleCustomModifiers={props.toggleCustomModifiers}
      onProductClose={props.onProductClose} />
  )
}
