import assign from 'object-assign';
import { EventEmitter } from 'events';

import Colors from './Colors';

import Config from '../Utils/Config';
import Device from '../Utils/Device';

let Theme = {
    obbar: Config.theme == "OBBAR",
    jjk: Config.theme == "JJK",
}

let ThemeStyles = assign({}, EventEmitter.prototype, {
    primaryBtnColor() {
        return {
            backgroundColor: Theme.obbar ? Colors.RustRed :
                Theme.jjk ? Colors.Ming :
                    Theme.RustRed,
            borderColor: Theme.obbar ? Colors.PeachPuff :
                Theme.jjk ? Colors.white :
                    Colors.PeachPuff,
        }
    },

    secondaryBtnColor() {
        return {
            backgroundColor: Theme.obbar ? Colors.PeachPuff :
                Theme.jjk ? Colors.LightCoral :
                    Colors.PeachPuff,
            borderColor: Theme.obbar ? Colors.Linen :
                Theme.jjk ? Colors.white :
                    Colors.Linen,
        }
    },

    primaryBtnFontColor() {
        return {
            color: Theme.obbar ? Colors.white :
                Theme.jjk ? Colors.white :
                    Colors.white
        }
    },

    secondaryBtnFontColor() {
        return {
            color: Theme.obbar ? Colors.RustRed :
                Theme.jjk ? Colors.white :
                    Colors.RustRed
        }
    },

    primaryFontColor() {
        return {
            color: Theme.obbar ? Colors.HeavyOrange :
                Theme.jjk ? Colors.Ming :
                    Colors.HeavyOrange
        }
    },

    primaryFontActiveColor() {
        return {
            opacity: 0.5,
            color: Theme.obbar ? Colors.HeavyOrange :
                Theme.jjk ? Colors.Ming :
                    Colors.HeavyOrange
        }
    },

    secondaryFontColor() {
        return {
            color: Theme.obbar ? Colors.Orange :
                Theme.jjk ? Colors.Ming :
                    Colors.Orange
        }
    },

    tersierFontColor() {
        return {
            color: Theme.obbar ? Colors.black :
                Theme.jjk ? Colors.Ming :
                    Colors.black
        }
    },

    primaryBgColor() {
        return {
            backgroundColor: Theme.obbar ? Colors.Orange :
                Theme.jjk ? Colors.Ming :
                    Colors.Orange
        }
    },

    secondaryBgColor() {
        return {
            backgroundColor: Theme.obbar ? Colors.HeavyOrange :
                Theme.jjk ? Colors.Ming :
                    Colors.HeavyOrange,
        }
    },

    tableNumberPosition() {
        return {
            position: 'absolute',
            bottom: Theme.obbar ? Device.height * 0.45 :
                Theme.jjk ? Device.height * 0.21 :
                    Device.height * 0.45
        }
    },

    customBtnBorder() {
        return {
            borderWidth: Theme.obbar ? 5 :
                Theme.jjk ? 0 :
                    0,
        }
    },

    activeBorderColor() {
        return {
            position: 'absolute',
            width: '40%',
            height: Device.getSize(1),
            left: '30%',
            bottom: -10,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: Theme.obbar ? Colors.HeavyOrange :
                Theme.jjk ? Colors.Ming :
                    Colors.HeavyOrange,
        }
    },

    iconColor() {
        return Theme.obbar ? Colors.HeavyOrange :
            Theme.jjk ? Colors.Ming :
                Colors.HeavyOrange
    },

    productBtn() {
        return {
            borderWidth: 1,
            borderColor: Theme.obbar ? Colors.Orange :
                Theme.jjk ? Colors.Ming :
                    Colors.Orange,
            paddingVertical: Device.getSize(5),
            paddingHorizontal: Device.getSize(15),
        }
    },

    ivoryBg() {
        return {
            backgroundColor: Theme.obbar ? Colors.Ivory :
                Theme.jjk ? Colors.Ivory :
                    Colors.Ivory
        }
    },

    expireQrLogo() {
        let width = Device.width * 0.5;
        let new_width = Math.sqrt((width * width) * 1.5);
        
        return {
            width: new_width,
            height: 10,
            borderRadius: 10,
            backgroundColor: Theme.obbar ? Colors.HeavyOrange :
                Theme.jjk ? Colors.Ming :
                    Colors.HeavyOrange,
            transform: [{rotateZ: '315deg'}],
            position: 'absolute',
            top: Device.width * 0.3,
            left: (width - new_width) / 2
        }
    },
});

export default ThemeStyles;
