import React from 'react';
import {Text, View} from 'react-native';

import styleHelper from '../Styles/StyleHelper';

import Services from '../Utils/Services';

const ExtraCharge = props => {
  const renderPrice = money => {
    if (money < 0) {
      money = 0;
    }
    return (
      <Text
        style={[
          styleHelper.fontBlack,
          styleHelper.font16,
          styleHelper.fontRegular,
        ]}>
        {Services.formatPrice(money)}
      </Text>
    );
  };

  if(!props.extraCharges){
      return <View />
  }

  return (
    <View>
      {props.extraCharges.map((extraCharge, k) => (
        extraCharge.amount > 0 &&
        <View
          key={k}
          style={[
            styleHelper.customContainerNormal2,
            styleHelper.flexRowSpaceBetween,
            // styleHelper.borderBottomWhiteTransparent,
          ]}>
          <View style={styleHelper.flexRowSpaceBetween}>
            {extraCharge.percentage && (
              <Text
                style={[
                  styleHelper.font12,
                  styleHelper.fontBlackTransparent,
                  styleHelper.fontRegular,
                  styleHelper.containerHorizontalSmall,
                ]}>
                {extraCharge.percentage}%
              </Text>
            )}
            <Text
              style={[
                styleHelper.font15,
                styleHelper.fontBlack,
                styleHelper.fontRegular,
              ]}>
              {extraCharge.string}
            </Text>
          </View>

          {renderPrice(extraCharge.amount)}
        </View>
      ))}
    </View>
  );
};

export default ExtraCharge;
