import Endpoint from './Endpoint';
import Services from './Services';

const BASE_URL = Endpoint.Endpoint;
const HOST = Endpoint.Host;
const AUTH_KEY = Endpoint.AUTH_KEY;

const emptyFunc = ()=>{};

let _suffixURL = ''; // add to the end of URL

const API = {
	//*****GET*****
  getStores(storeID, callback){
    const url = '/odoo+menu+integration+1/pos.shop';
    if(storeID){
      _suffixURL = storeID;
    }

    return get(url, callback);
  },

  getMenu(storeID, callback){
    const url = '/odoo+menu+integration+2/pos.shop';

    if(storeID){
      _suffixURL = storeID;
    }
    return get(url, callback);
  },

  getProduct(productId, callback){
    const url = '/odoo+menu+integration+2/product.template';

    if(productId){
      _suffixURL = productId;
    }
    return get(url, callback);
  },

  getCategory(callback){
    const url = '/odoo+menu+integration+1/pos.category';

    return get(url, callback);
  },

  getCategoryById(categoryId, callback){
    const url = '/odoo+menu+integration+1/pos.category';

    if(categoryId){
      _suffixURL = categoryId;
    }
    return get(url, callback);
  },

  createOrder(data, callback){
    const url = '/pos.order/create_order';

    return post(url, data, callback);

    // authenticate({
    //   success: (res)=>{
    //     try {
    //       if(res.result) {
    //         return post(url, data, res.result.session_id, callback);
    //       } else {
    //         (callback.error || emptyFunc)(res);
    //       }
    //     } catch(BreakException) {
    //     }
    //   }
    // });
  },

  postOrder(data, callback){
    const url = '/pos.order/calculate_price';

    return post(url, data, callback);

    // authenticate({
    //   success: (res)=>{
    //     try {
    //       console.log(res)
    //       if(res.result) {
    //         return post(url, data, res.result.session_id, callback);
    //       } else {
    //         (callback.error || emptyFunc)(res.error.data.message);
    //       }
    //     } catch(BreakException) {
    //     }
    //   },
    //   error: (err)=>{
    //     console.log(err.error.data.message)
    //   }
    // });
  },

  getTssCustomModifierMapping(callback){
    const url = '/odoo+menu+integration+1/modifier.mapping';

    return get(url, callback);
  },

  getOrder(token, callback) {
    const url = '/pos.order/get_order/' + token;

    return getV2(url, {}, callback);
  }
}

/** New Odoo Enpoint. 2022 Jul 27 */
async function get(url, callback) {
  try{
    const ajaxURL = `${BASE_URL}${url}/${_suffixURL}`;
    _suffixURL = '';
    // console.log(ajaxURL)

    const response = await fetch(ajaxURL, {
      method: 'GET',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Basic ' + AUTH_KEY
      }
    });

    callbackAfterFetch(response, callback);
  } catch (error){
    distinguishAPIError(callback, error);
  }
}

async function post(url, data, callback) {
  try{
    const ajaxURL = `${HOST}${url}/${_suffixURL}`;
    _suffixURL = '';

    const response = await fetch(ajaxURL, {
      method: 'POST',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        // 'X-Openerp': session
        // 'Authorization': 'Basic ' + AUTH_KEY
      },
      body: JSON.stringify(data)
    });

    callbackAfterFetch(response, callback);
  } catch (error){
    distinguishAPIError(callback, error);
  }
}

async function getV2(url, body, callback) {
  try{
    const ajaxURL = `${HOST}${url}`;

    const response = await fetch(ajaxURL, {
      method: 'GET',
      credentials: 'omit',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Basic ' + AUTH_KEY
      }
    });

    callbackAfterFetch(response, callback);
  } catch (error){
    distinguishAPIError(callback, error);
  }
}

// async function authenticate(callback) {
//   const url = '/web/session/authenticate';
//   let ajaxURL = `${HOST}${url}`;

//   let data = {
//     jsonrpc: "2.0",
//     params: {
//       db: DB,
//       login: LOGIN,
//       password: PASSWORD
//     }
//   };

//   const res = await fetch(ajaxURL, {
//     method: 'POST',
//     credentials: 'include',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json',
//       // 'Access-Control-Request-Credentials': true
//     },
//     body: JSON.stringify(data)
//   });

//   console.log(res)
//   console.log(res.headers.get('Content-Type'))
//   console.log(res.headers.get('set-cookie'))

//   // ajaxURL = `${HOST}/web/session/get_session_info`;
//   // data = {
//   //   jsonrpc: "2.0",
//   //   params: {}
//   // };

//   // const response = await fetch(ajaxURL, {
//   //   method: 'POST',
//   //   credentials: 'omit',
//   //   headers: {
//   //     'Accept': 'application/json',
//   //     'Content-Type': 'application/json'
//   //   },
//   //   body: JSON.stringify(data)
//   // });

//   callbackAfterFetch(response, callback);
// }

// async function patch(url, data, callback) {
//   try{
//     const ajaxURL = `${BASE_URL}${url}/${_suffixURL}`;
//     _suffixURL = '';

//     const response = await fetch(ajaxURL, {
//       method: 'PATCH',
//       credentials: 'omit',
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'Authorization': 'Basic ' + AUTH_KEY,
//       },
//       body: JSON.stringify(data)
//     });

//     callbackAfterFetch(response, callback);
//   } catch (error){
//     distinguishAPIError(callback, error);
//   }
// }

// function SouperApi(url, callbackSuccess, callbackFailed, callbackErr, skipResultCheck = false){
//   fetch(`${SOUPERAPI_MOBILE_ENDPOINT}/${url}`, {
//     method: 'GET',
//     credentials: 'omit',
//     headers: {
//       'X-Api-Key': SOUPERAPI_AUTH_KEY,
//       'Accept': 'application/json',
//     }
//   })
//   .then(response => response.json())
//   .then(data => {
//     if (data.result == 'Success' || skipResultCheck) {
//       callbackSuccess(data);
//     } else {
//       callbackFailed(data.message);
//     }
//   })
//   .catch(err => {
//     callbackErr(err);
//   })
//   .finally(()=>{
//       Services.hideSpinner();
//   });
// }

// function SouperApiPost(url, requestBody, callbackSuccess, callbackFailed, callbackErr, skipResultCheck = false){
//   fetch(`${SOUPERAPI_MOBILE_ENDPOINT}/${url}`, {
//     method: 'POST',
//     credentials: 'omit',
//     headers: {
//         'X-Api-Key': SOUPERAPI_AUTH_KEY,
//         'Content-Type': 'application/json',
//         'Access-Control-Allow-Origin': '*'
//       },
//     body: JSON.stringify(requestBody)
//   })
//   .then(response => response.json())
//   .then(data => {
//     if (data.result == 'Success' || skipResultCheck) {
//       callbackSuccess(data);
//     } else {
//       callbackFailed(data.message);
//     }
//   })
//   .catch(err => {
//     callbackErr(err);
//   })
//   .finally(()=>{
//       Services.hideSpinner();
//   });
// }

function distinguishAPIError(callback, error){
  //Detect whether this api error is because of no connections
  // NetInfo.isConnected.fetch().done(isConnected => {
  //     if(Storage.Components.Spinner){
  //         Storage.Components.Spinner.hide();
  //     }
  //     //If the device still has network conntection, pass through the api error
  //     if (isConnected == true) {
  //       callbackAPIError(callback, error);
  //     }
  //     else {
  //       //If device loss network connection, show no connections alert
  //       Services.showLostConnectionAlert()
  //     }
  // })
  callbackAPIError(callback, error);
}

const callbackAfterFetch = (response, callback)=>{
  generateResponseJson(response).then((json)=>{
    let responseJson = json;

    // console.log('*********DEBUG************');
    // console.log(response);
    // console.log(responseJson);
    // console.log('**************************');


    if(callback){
      callbackComplete(callback.complete);
      if(response.ok === false){
        (callback.error || emptyFunc)(responseJson);
      } else {
        // if(responseJson.success === false){
        //   (callback.error || emptyFunc)(responseJson);
        // }else{
          (callback.success || emptyFunc)(responseJson);
        // }
      }
    }

  })
}

async function generateResponseJson(response){
  try{
    return await response.json();
  } catch(error){
    if(response.status === 500){
      return 'Server error!';
    }
    if(response.status === 404){
      return 'URL not found!';
    }
  }
}

async function callbackComplete(callback){
  try{
    await (callback || emptyFunc)();
  } catch(error){
    Services.showAlertError(error.message);
  }
}

const callbackAPIError = (callback, error)=>{
  if(callback){
    (callback.complete || emptyFunc)();
    (callback.error || emptyFunc)(error.message);
  }
}

export default API;
