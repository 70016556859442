import React from 'react';
import {Alert, InteractionManager} from 'react-native';
import Storage from './Storage';
import Config from './Config';
import Device from './Device';
import moment from 'moment';

const emptyFunc = ()=>{};

let Services = {
	showSideView(){
		Storage.Components.SideView.show();
	},
	hideSideView(){
		Storage.Components.SideView.hide();
	},
	showSpinner(){
		if(Storage.Components.Spinner){
			Storage.Components.Spinner.show();
		}
	},
	hideSpinner(){
		Storage.Components.Spinner.hide();
	},
	onFocusCallback(callback){
		if(typeof callback === 'function'){
			InteractionManager.runAfterInteractions(() => {
				callback();
			});
		}
	}, // Used after navigator transition
	parseErrorMsg(error){
		if(typeof error === 'string'){
			return error;
		}
		if(error){
			if(error.error){
				if(error.error.messages){
					var messages = error.error.messages;
					return messages.reduce((previous, current) => `${previous}\n${current}` )
				}

				return error.error;
			}
			
			if(typeof error.message === 'string'){
				return error.message;
			}

			if( Object.prototype.toString.call(error) === '[object Array]' ){
				return error.reduce((previous, current) => `${previous}\n${current}` )
			}
		}
		
		return "Something's wrong!";
	},	

	validateEmail(email) {
    return /^([\w-]+(?:(\.|\+)[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(email);
  },
  validatePassword(password){
  	return (/(?=.*?[0-9])(?=.*?[\~\!\@\#\$\%\^\&\*\(\)\_\+\|\}\{\"\:\?\>\<\,\.\/\;\'\-\=\`\[\]\\]).{6,}$/).test(password);
  },
  showAlertError(message, callback){
	if(/.*(token).*(not).*(found)/.test(message) || (message && message.code === -666))
	{
		// skip this damn annoying alert
	}
	else
	{
		alert(
			this.parseErrorMsg(message)
		);
	}
  },
  showAlert(title, message, callback){
  	Alert.alert(
		  title,
		  message,
		  [
		    { text: 'OK', onPress: ()=> (callback || emptyFunc)() },
		  ],
		  { cancelable: false }
		)
  },
  showConfirm(title, message, callback, button={}, callbackCancel){
  	Alert.alert(
		  title,
		  message,
		  [
		    { text: button.textCancel || 'Cancel', onPress: ()=> (callbackCancel || emptyFunc)()  },
		    { text: button.textAgree || 'OK', onPress: ()=> (callback || emptyFunc)() },
		  ],
		  { cancelable: false }
		)
  },
  showContinue(title, message, callback, button={}, callbackCancel){
	Alert.alert(
		title,
		message,
		[
		  { text: button.textAgree || 'Continue', onPress: ()=> (callback || emptyFunc)() },
		  { text: button.textCancel || 'Exit', onPress: ()=> (callbackCancel || emptyFunc)()  },
		],
		{ cancelable: false }
	  )
  },
  showLostConnectionAlert() {
	this.showAlert('Unable to Connect','Please check internet connection to proceed')
  },
  trimTextInObject(obj){
  	Object.keys(obj).forEach(function (key) {
      obj[key] = obj[key].trim();
    })
  },

    clearStringInObject(obj){
        Object.keys(obj).forEach(function (key) {
            obj[key] = '';
        })
    },

    capitalize(str){
		if(str){
			str = str.toLowerCase().split(' ');

		  for(var i = 0; i < str.length; i++){
		    str[i] = str[i].split('');
		    str[i][0] = str[i][0].toUpperCase(); 
		    str[i] = str[i].join('');
		  }
		  return str.join(' ');
		}
		return str;
	},
	getTodayDateTime(){
		return moment().format(Config.formatDateTime);
	},
	getTodayDate(){
		return moment().format(Config.formatDate);
	},
	formatDate(date){
		if(date){
			// return moment(date).utcOffset('+8:00').format(Config.formatDate);
			// moment utcOffset doesn't work on Android so we use add function here to manually add 8 hours into the date		
			return moment(date).add(8, 'hours').format(Config.formatDate)
		}
		return '';
	},
	formatDateTime(time){
		if(time){
			return moment(time).format(Config.formatDateTime);
		}
		return null;
	},
	formatCartSummaryDateTime(time){
		if(time) {
			return moment(time).format(Config.cartSummaryDateTimeFormat);
		}
		return null
	},
	formatDateTimeText(datetime){
		if(datetime){
			return moment(datetime).format(Config.formatDateTimeText);	
		}
		return '';
	},
	formatTime(datetime){
		if(datetime){
			return moment(datetime).format(Config.formatTime);
		}
	},
	formatYMD(time){
		if(time){
			return moment(time).format(Config.formatYMD);
		}
	},
	formatSuperholicDate(time) {
		if (time) {
			return moment(time).format(Config.formatSuperholic)
		}
	},
	convertToEpochTime(datetime){
		//MMM DD, YYYY h:mm A
		if(datetime){
			return new Date(moment(datetime, Config.formatDateTime)).getTime();
		}
	},
	convertMMMDDYYYYToEpochTime(date){
		if(date){
			return new Date(moment.utc(date, Config.formatDate)).getTime();
		}
		return null;
	},
	getImageURL(id, type){
		switch(type){
			case 'placeholder': 
				return `${Config.url_image}w_50/${id}`;ç
				break;
			case 'fullscreen':
				return `${Config.url_image}w_${Device.width+100}/${id}`;
				break;
			case 'kiosk':
				return `${Config.url_image}w_${Device.width}/${id}`;
				break;
			case 'slider':
				return `${Config.url_image}w_${Device.width}/${id}`;
				break;
			case 'product':
				return `${Config.url_image}model=product.template&field=image&id=${id}`;
				break;
			case 'category':
				return `${Config.url_image}model=pos.category&field=image&id=${id}`;
				break
			default:
				return `${Config.url_image}c_fit,h_${Config.imageSizeLandscape.width+100},w_${Config.imageSizeLandscape.height+100}/${id}`;
		}
	},
	findMatchItemInArrays(subarr, arr){
		let matchItem;
		subarr.some((e, i)=>arr.some((e2,i2)=>{
			if(e2 === e) matchItem = e2;
			return e2===e;
		}));

		return matchItem;
	},
	convertArrayToObject(arr, attr){
		return arr.reduce((obj, item)=>{obj[item[attr]]=item; return obj},{});
	},
	convertObjectToArray(obj){
		let arr = [];
		Object.keys(obj).forEach((key, i)=>{
			arr.push(obj[key]);
		})
		return arr;
	},
	popToRoute(name){
		let route = Storage.Navigator.getCurrentRoutes().filter((route)=>(route.name === name));
		if(route.length > 0){
			return Storage.Navigator.popToRoute(route[0]);
		}
		return false;
	},	

	getImageScaledSize(width, height, expectWidth){
		let ratio = width / height,
				newWidth, newHeight;

		if(ratio > 1){
			newWidth = expectWidth;
      		newHeight = expectWidth / ratio;
		} else {
			newWidth = expectWidth * ratio;
      		newHeight = expectWidth;
		}

		return {
			width: newWidth,
			height: newHeight
		}
	},
	formatPrice(price){
		if(Number.isFinite(price) === true){
			return `$${price.toFixed(2)}`;
		}
		return null;
	},
	cloneObject(obj){

		//return _cloneObject(obj);
		return JSON.parse(JSON.stringify(obj));
	},
	findObjectInArray(arr, obj){
		let result=null;
		let position=null;

		if(obj && typeof(obj) === 'object' && Object.keys(obj).length > 0){
			arr.some((e, i)=>{
				let isMatched = Object.keys(obj).every((key, i)=>obj[key]===e[key]);
				if(isMatched === true){
					position = i;
					result = e;
				}
				return isMatched;
			})
		}
		return {position, result: result};
	},
	parseOrderType(type){
		switch(type){
			case 'takeaway':
				// return 'takeAway';
				return 'take_away';
				break;
			case 'dinein':
				// return 'dineIn';
				return 'dine_in';
				break;
			default: 
				return null;
		}
	},
	parseOrderTypeV2(type){
		switch(type){
			case 'takeaway':
				// return 'takeAway';
				return 'allow_take_away';
				break;
			case 'dinein':
				// return 'dineIn';
				return 'allow_dine_in';
				break;
			default: 
				return null;
		}
	},
	convertTextToArray(text){
		//Add bullet
		//return '\u2022 ' + text.replace(/\r\n/g, '\r\n\u2022 ');
		if(text){
			return text.split('\r\n');
		}
		return [];
	},
	getDiscountDisplay(discounts){
		return discounts.map((discount)=>({
      type: discount.amount ? 'amount':'percentage',
      value: (discount.amount || discount.percentage) || 0,
      reason: discount.reason,
      promotion: discount.promotion,
      voucher: discount.voucher,
    }))
	},

	parseDiscountValueByType(type, value){
		return type === 'amount' ? `-${Services.formatPrice(value)}` : `${value}%`
	},

	removeArrayElement(arr, e){
		let index = arr.indexOf(e);
		if(index > -1){
			arr.splice(index,1);
			return true;
		}
		return false;
	},
	removeArrayFromArray(arrSub, arr){
		return arr.filter((e)=>arrSub.indexOf(e) === -1);
	},
	getParamFromUrl(url, paramName){
    let regexS = "[\\?&]"+paramName+"=([^&#]*)",
        regex = new RegExp( regexS ),
        results = regex.exec(url);
    
    paramName = paramName.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");

    if( results == null ){
        return '';
    }
    else {
        return results[1];
    }
  },
}

export default Services;
