import { Endpoint, Host, AUTH_KEY } from '@env';

console.log('._.')
const Ep = {
	Endpoint,
	Host,
	AUTH_KEY
};

export default Ep;
