import React from 'react';
import { Platform, Dimensions, PixelRatio } from 'react-native';

const deviceWidth=Math.round(Dimensions.get('window').width);
const deviceHeight=Math.round(Dimensions.get('window').height);

const Device = {
	getSize(size){
		//Scale pixel up for tablets
		return Math.round(PixelRatio.roundToNearestPixel(size * this.pixelScale));
	},
	getRatio(size) {
		//Scale pixel up for tablets
		return size * this.pixelScaleV2;
	},
	pixelScale: (()=>{
		if(deviceWidth >= 768) return 1.5;
		if(deviceWidth >= 375) return 1.1;
		return 1;
	})(),
	pixelScaleV2: (() => {
		if (deviceHeight >= 900) {
		    return 1.45;
		}
		if (deviceHeight >= 800) {
		    return 1.4;
			// return 1.5;
			// return 1.6;
		}
		if (deviceHeight >= 700) {
			return 1.37;
			// return 1.47;
			// return 1.55;
		}
		if (deviceHeight >= 650) {
			return 1.33;
			// return 1.43;
			// return 1.45;
		}
		if (deviceHeight >= 600) {
			return 1.3;
			// return 1.43;
			// return 1.45;
		}
		if (deviceHeight >= 550) {
			return 1.25;
			// return 1.35;
			// return 1.3;
		}
		if (deviceHeight >= 500) {
			return 1.2;
			// return 1.35;
			// return 1.3;
		}
		if (deviceHeight >= 450) {
		//   return 1.25;
		    return 1.1;
		}
		if (deviceHeight >= 400) {
			//   return 1.25;
				return 1;
		}
		return 1;
	})(),
	isSmallScreen: deviceWidth < 375,
	isTablet: deviceWidth >= 768,
	isBefore23: Platform.Version < 23,
	isBefore22: Platform.Version < 22,
	isBefore21: Platform.Version < 21,
	isBefore19: Platform.Version < 19,
	isAfter21: Platform.Version >= 21,
	is16: Platform.Version === 16,
	is21: Platform.Version === 21,
	is22: Platform.Version === 22,
	is23: Platform.Version === 22,
	isIOS: Platform.OS === 'ios',
	isAndroid: Platform.OS === 'android',
	isWeb: Platform.OS === 'web',
	width: deviceWidth,
	height: deviceHeight,
}

// module.exports = Device;
export default Device
