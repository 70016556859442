import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { AppRegistry, Text, View } from 'react-native';
import {NavigationContainer} from '@react-navigation/native';

import {expo} from './app.json';

import useFonts from './UseFonts';

import {navigationRef} from './App/Routes/Routing';
import ParentNavigator from './App/Routes/ParentStack';

import Spinner from './App/Components/Spinner';

export default class SoupSpoonWeb extends React.Component {
  async _loadFontsAsync() {
    await useFonts();
  }

  componentDidMount() {
    this._loadFontsAsync();
  }

  render() {
    try {
      const headTag = document.querySelector("head");
      const icon = document.createElement("link");
      const attributeRel = document.createAttribute("rel");
      attributeRel.value = "icon";
      const attributeHref = document.createAttribute("href");
      attributeHref.value =
        "";
      icon.setAttributeNode(attributeRel);
      icon.setAttributeNode(attributeHref);
      headTag.appendChild(icon);
    } catch (e) {
      //Browser tabs do not exist on android and iOS, so let's just do nothing here.
    }

    return (
      <NavigationContainer ref={navigationRef}>
        <StatusBar
          barStyle="light-content"
          backgroundColor="#FFF"
          />
        <ParentNavigator />
        <Spinner />
      </NavigationContainer>
    );
  }
}

AppRegistry.registerComponent(expo.name, () => SoupSpoonWeb);
