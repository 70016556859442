import AppDispatcher from '../Dispatcher/AppDispatcher';
import Constants from '../Constants/AppConstants';

import moment from 'moment';
import assign from 'object-assign';
import {EventEmitter} from 'events';

import Services from '../Utils/Services';

let _data = {
  type: null, // takeaway || dinein
  epochTime: null, // epoch time for TakeAway
  store: null,
  table: null,
  tableId: null
};

let _selectedVariant;
/*
  "id": 54,
  "price": 11.2,
  "modifierGroups": [
    "id": 44,
    "name": "Choose 1 Soup for your Sandwich Set (Regular)",
    "minModifiers": 1,
    "maxModifiers": 1,
    "childGroups": [],

    numberSelectedModifier: 0,
    isSelectedModifierValid: true/false

    "modifiers": [
      {
        "id": 706,
        "name": "Meatless Minestrone",
        "price": 0,
        "minQuantity": 0,
        "maxQuantity": 1,
        "preSelected": false,
        "sortIndex": 0,
        "product": null
    },
    ]

*/

let _currentSubtotal;
let _selectedProduct;
let _selectedProduct2;
let _dateTimeText; ////MMM DD, YYYY h:mm:ss A
let _isSelectedModifierValid = true; // check number of selected modifier with minModifiers/maxModifiers
let _orderType = 'dinein';

const _assignQuantityToModifiers = ()=>{
  /*
    _ Assign Quantity = 0 if quantity == null
    _ Assign Quantity = 1 if preSelected == true
  */

  if(_selectedProduct.product_variant_ids && _selectedProduct.product_variant_ids.length > 0){
    _selectedProduct.product_variant_ids.forEach((variant)=>{
      _initEachModifierQuantityToVariant(variant);
    })
  } else {
    _initEachModifierQuantityToVariant(_selectedProduct.product_variant_ids[0]);
  }
}

const _initEachModifierQuantityToVariant = (variant, isForced)=>{
  /*
    isForced: used to reset quantity when switching between variants in Product screeen
  */
  variant.modifier_group_line_ids.forEach((modifierGroup)=>{
    modifierGroup.product_modifier_ids.forEach((modifier)=>{
      if(!modifier.isAddedToCart || isForced === true){
        modifier.isAddedToCart = false;
        if(modifier.pre_selected === true){
          modifier.quantity = 1;
        } else {
          modifier.quantity = 0;  
        }
      }
    })
  })
}

const _deselectVarianModifiers = ()=>{
  if(_selectedVariant){
    _initEachModifierQuantityToVariant(_selectedVariant, true);
  }
}

const _calculateCurrentSubtotal = ()=>{
  let currentSubtotal = 0;

  _isSelectedModifierValid = true;

  if(_selectedVariant.lst_price){
    currentSubtotal = _selectedVariant.lst_price;
  } else {
    currentSubtotal = _selectedProduct.list_price;
  }

  _selectedVariant.modifier_group_line_ids.forEach((modifierGroup)=>{
    let numberSelectedModifier = 0;
    modifierGroup.product_modifier_ids.forEach((modifier)=>{
      if(modifier.quantity > 0){
        numberSelectedModifier+=modifier.quantity; // Track number of selected modifier
        currentSubtotal += (modifier.price * modifier.quantity);
      }
    })
    modifierGroup.numberSelectedModifier = numberSelectedModifier; 
    if(_isSelectedModifierValid === true){
      _checkSelectedModifierValid(modifierGroup, numberSelectedModifier);
    }
  })

  _currentSubtotal = currentSubtotal;

}

const _findSelectedVariant = (product)=>{
  let {position, result} = Services.findObjectInArray(product.product_variant_ids, {isAdded: true});
  return result;
}

const _checkSelectedModifierValid = (modifierGroup, numberSelectedModifier)=>{
  //Check number of selected modifier with minQuantity, maxQuantity
  if(numberSelectedModifier < modifierGroup.min_modifier || numberSelectedModifier > modifierGroup.max_modifier ) {
    _isSelectedModifierValid = false;
  }
}

const   SELECT_VARIANT_EVENT = 'select_variant',
        CHANGE_VARIANT_EVENT = 'change_variant';

let OrderStore = assign({}, EventEmitter.prototype, {
  getData(){
    return _data;
  },
  getStore(){
    return _data.store;
  },
  
  setType(type){
    _data.type = type.toLowerCase();
  },

  setType2(type){
    _orderType = type.toLowerCase();
  },

  setTable(table){
    _data.epochTime = null;
    _dateTimeText = null;

    _data.table = table;
    _data.isValid = true;
  },

  setTableId(tableId){
    _data.tableId = tableId;
  },

  setEpochTime(epochTime){
    _data.table = null;
    _data.epochTime = epochTime;
    _dateTimeText = Services.formatCartSummaryDateTime(epochTime);
  },

  setStore(store){
    _data.store = store;
  },

  setSelectedVariant(variant){
    if(_selectedVariant){
      _selectedVariant.isAdded = false;
    }
    _deselectVarianModifiers();
    _selectedVariant = variant;
    _selectedVariant.isAdded = true;
    _calculateCurrentSubtotal();
    this.emitChangeVariant();
  },

  setSelectedModifier(modifier, quantity){
    //quantity: add: 1/ remove: -1

    modifier.quantity+=quantity;
    (modifier.quantity) < 0 && (modifier.quantity = 0);
    _calculateCurrentSubtotal();
    this.emitChangeVariant();
  },

  SelectModifierV2(modifier, modifierGroup){
    modifierGroup.product_modifier_ids.forEach((modifierr,i)=>{
      if(!modifierr.pre_selected) {
        modifierr.quantity = 0;
      }
    });

    modifier.quantity+=1;
    (modifier.quantity) < 0 && (modifier.quantity = 0);
    _calculateCurrentSubtotal();
    this.emitChangeVariant();
  },

  resetModifierGroup(modifierGroup) {
    modifierGroup.product_modifier_ids.forEach((modifierr,i)=>{
      if(!modifierr.pre_selected) {
        modifierr.quantity = 0;
      }
    });
    this.emitChangeVariant();
  },

  setSelectedProduct(product){
    _selectedProduct = Services.cloneObject(product);

    if(_selectedProduct.product_variant_ids && _selectedProduct.product_variant_ids.length > 0) {
      _selectedVariant = _findSelectedVariant(_selectedProduct);
      if(!_selectedVariant){
        _selectedVariant = _selectedProduct.product_variant_ids[0];
      }
    } else {
      //Assign product.modifierGroups to product.variants if variants do not exist
      _selectedVariant = {modifier_group_line_ids: _selectedProduct.product_variant_ids[0].modifier_group_line_ids};
    }
    _selectedVariant.isAdded = true;
    _assignQuantityToModifiers();
    _calculateCurrentSubtotal();
  },

  getType(){
    return _data.type;
  },
  getType2(){
    return _orderType;
  },
  getTypeText(){
    return "TSS QR Ordering.";
  },
  getEpochTime(){
    return _data.epochTime;
  },
  getDatetime(){
    //MMM DD, YYYY h:mm:ss A
    return _dateTimeText;
  },
  getTable(){
    return _data.table;
  },
  getStore(){
    return _data.store;
  },
  getStoreName() {
    return `${_data.store.name}`;
  },
  getSelectedProduct(){
    return _selectedProduct;
  },
  getSelectedVariant(){
    return _selectedVariant;
  },
  getCurrentSubtotal(){
    return _currentSubtotal;
  },
  getModifierGroups(){
    if(_selectedVariant) {
      if(_selectedVariant.modifier_group_line_ids)
      {
        return _selectedVariant.modifier_group_line_ids;
      }
    }
    return null;
  },
  isSelectedModifierValid(){
    return _isSelectedModifierValid;
  },

  emitChangeVariant(){
    this.emit(SELECT_VARIANT_EVENT);
  },

  addChangeVariantListener(callback){
      this.on(SELECT_VARIANT_EVENT, callback);
  },
  removeChangeVariantListener(callback) {
      this.removeListener(SELECT_VARIANT_EVENT, callback);
  },

  clearSelectedStore(){
    _data.epochTime = null;
    _data.store = null;
    _data.table = null;
  },

  checkModifierGroupsValid() {
    let modifierGroupsValid = [];
    _selectedVariant.modifier_group_line_ids.forEach((modifierGroup)=>{
      let numberSelectedModifier = 0;
      modifierGroup.product_modifier_ids.forEach((modifier)=>{
        if(modifier.quantity > 0){
          numberSelectedModifier+=modifier.quantity; // Track number of selected modifier
        }
      })
      modifierGroup.numberSelectedModifier = numberSelectedModifier; 
      if(numberSelectedModifier < modifierGroup.min_modifier || numberSelectedModifier > modifierGroup.max_modifier ){
        modifierGroupsValid.push(false);
      } else {
        modifierGroupsValid.push(true);
      }
    });

    return modifierGroupsValid;
  },
})

OrderStore.dispatchData = AppDispatcher.register((action)=>{
  switch(action.type) {
    case Constants.ActionTypes.SET_ORDER_TYPE:
      OrderStore.setType(action.orderType);
      break;
    case Constants.ActionTypes.SET_ORDER_TYPE_2:
      OrderStore.setType2(action.orderType);
      break;
    case Constants.ActionTypes.SELECT_VARIANT:
      OrderStore.setSelectedVariant(action.variant);
      break;
    case Constants.ActionTypes.SELECT_MODIFIER:
      OrderStore.setSelectedModifier(action.modifier, 1);
      break;
    case Constants.ActionTypes.DESELECT_MODIFIER:
      OrderStore.setSelectedModifier(action.modifier, -1);
      break;
    case Constants.ActionTypes.RESET_MODIFIER:
      OrderStore.resetModifierGroup(action.modifierGroup);
      break;
    case Constants.ActionTypes.SELECT_MODIFIER_V2:
      OrderStore.SelectModifierV2(action.modifier, action.modifierGroup);
      break;
    case Constants.ActionTypes.SELECT_PRODUCT:
      OrderStore.setSelectedProduct(action.product);
      break;
  }
})

export default OrderStore;
