import React from 'react';
import {
  Text,
  View,
  Image
} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';

import styleHelper from '../Styles/StyleHelper';
import ThemeStyles from '../Styles/ThemeStyles';

import ImageBackground from '../Components/ImageBackground';

import Device from '../Utils/Device';

export default class Expired extends React.Component {
  render() {
    return(
        <ImageBackground>
            <View style={[styleHelper.containerTopFit, styleHelper.flexCenter]}>
                <View>
                  <Ionicons name="qr-code-outline" size={Device.width * 0.5} color={ThemeStyles.iconColor()} style={[{opacity: 0.5}]} />

                  <View style={[ThemeStyles.expireQrLogo()]} />
                </View>

                <Text style={[ThemeStyles.secondaryFontColor(), styleHelper.font16, styleHelper.fontBold, styleHelper.flexCenter, styleHelper.textCenter, { marginVertical: Device.getSize(10) }]}>
                    TABLE QR EXPIRED.
                </Text>

                <Text style={[ThemeStyles.secondaryFontColor(), styleHelper.font16, styleHelper.fontBold, styleHelper.flexCenter, styleHelper.textCenter]}>
                    Please approach service staff for assistance. Thank you.
                </Text>
            </View>
        </ImageBackground>
    );
  }
}
