import moment from 'moment';
import assign from 'object-assign';
import { EventEmitter } from 'events';

import API from '../Utils/API';
import Services from '../Utils/Services';
import Config from '../Utils/Config';

import AppDispatcher from '../Dispatcher/AppDispatcher';
import Constants from '../Constants/AppConstants';

const CHANGE_EVENT = 'change',
    SELECT_EVENT = 'select',
    SELECT_TABLE_EVENT = 'select_table',
    SELECT_TIME_EVENT = 'select_time',
    UNAVAILABLE_STORE = 'unavailable_store',
    MSG_STORE_UNAVAILABLE = 'Store is closed';

const formatDate = 'MMM DD, YYYY h:mm:ss a';
const TIME_FORMAT = 'HH:mm:ss';

const emptyFunc = () => { };

let _data = [];
let _selectedStore = {};
let _selectedTable = {};
let _selectedTableId = 0;
// {
//   "id": 4,
//   "number": "03B"
// }

let _selectedEpochTime = null; // miliseconds

let _selectedDayTakeAway = null;
/* 1 of the day as below
 "takeAwayHours": [
    {
      "dayOfWeek": "SUNDAY",
      "startTime": "11:00:00.000",
      "endTime": "22:00:00.000"
    },
    {
      "dayOfWeek": "MONDAY",
      "startTime": "11:00:00.000",
      "endTime": "22:00:00.000"
    },
*/

let _type; // takeaway || dinein

let _timeETA = 0, // Time to prepare a meal, in MINUTES
    _epochETA = 0; //Time to prepare a meal, in Miliseconds

let _isDineInAvailable = false; //Check DineIn day of week from API
let _posStoreCode = 0;
let _categoryId = 0;
let _configId = 0;
let _tableId = 0;
// let _suggestionId = 0;
// let _showSuggestionOn = 0;
// let _homeSliderId = 0;
let _token = "";
let _summaryIp = 0;
let _sessionId = 0;
let _pricelistId = 0;
let _userId = 0;

// const _findSelectedDayTakeAway = (store, miliseconds)=>{
// 	return store['take_away_hours'].filter((date)=>date.day_of_week.toLowerCase() === moment(miliseconds).format('dddd').toLowerCase())[0];
// }

const _findSelectedDayDineIn = (store) => {
    return store['dine_in_hours'].filter((date) => date.day_of_week.toLowerCase() === moment(new Date()).format('dddd').toLowerCase())[0];
}

const _isDineInAvailableInTime = (dayOfWeek) => {
    if (dayOfWeek) {
        let startEpoch = moment(dayOfWeek.start_time, TIME_FORMAT).valueOf(),
            endEpoch = moment(dayOfWeek.end_time, TIME_FORMAT).valueOf();
        let currentEpoch = (new Date()).getTime();

        if (currentEpoch > startEpoch && currentEpoch < endEpoch) {
            return true;
        }
    }

    return false;
}

const _getData = (storeID, callback = {}) => {
    API.getStores(storeID, {
        success: (res) => {
            let data = [];
            data.push(res)
            _data = data;
            StoreStore.emitChange();
        },
        error: (error) => {
            Services.showAlertError(error);
        },
        complete: () => {
            (callback.complete || emptyFunc)();
        }
    })
};

const _ajaxETA = (store) => {
    _timeETA = store.kitchen_prep_time + store.buffer_time;
    _epochETA = _timeETA * 60 * 1000;
    StoreStore.checkDineInAvailable(store);
    _selectedTable = null;

    StoreStore.emitSelect();
}


let StoreStore = assign({}, EventEmitter.prototype, {
    getAll() {
        return _data;
    },
    getAllStoreForSelectedType() {
        return this.getAllStoreForType(_type);
    },
    getSelectedStore() {
        return _selectedStore;
    },
    checkStoreAvailableForType(type) {
        return this.getAllStoreForType(type).length > 0;
    },
    getAllStoreForType(type) {
        let orderType = Services.parseOrderTypeV2(type);
        return _data.filter((store) => store[orderType] == true);
    },
    getSelectedDayTakeAway() {
        return _selectedDayTakeAway;
    },

    getSelectedTable() {
        return _selectedTable || {};
    },
    getType() {
        return _type;
    },
    getEpochTime() {
        return _selectedEpochTime;
    },
    getDateTime() {
        //MMM DD, YYYY h:mm A
        return Services.formatDateTime(_selectedEpochTime);
    },
    getTables() {
        if (_isDineInAvailable === true) {
            return _selectedStore.tables;
        } else {
            return [];
        }
    },

    // getEarliestTakeAwayEpochTime(){
    //     /*
    //     _ Set TakeAway date/time picker to earliest day of a store (TaweAway hours + ETA)
    //     */
    //     let epochToday = (new Date()).getTime(),
    //         todayTakeAway = _findSelectedDayTakeAway(_selectedStore, (new Date()).getTime()),
    //         timeEarliest = epochToday + _epochETA;

    //     if(todayTakeAway){
    //         let epochStartTime = moment(todayTakeAway.startTime, 'HH:mm:ss').valueOf();
    //         epochStartTime += _epochETA;
    //         if(epochStartTime > epochToday){
    //             timeEarliest = epochStartTime;
    //         }

    //         return timeEarliest;
    //     }

    //     return epochToday + _epochETA;

    // },

    getETA() {
        return _timeETA; //minute
    },


    clear() {
        _data = [];
    },

    setSelectedStore(store, posStoreCode, categoryId, token, summaryIp, sessionId, pricelistId, userId, configId, tableId) {
        _selectedStore = store;
        _posStoreCode = posStoreCode;
        // _categoryId = categoryId;
        _configId = configId;
        _tableId = tableId;
        _token = token;
        _summaryIp = summaryIp;
        _sessionId = sessionId;
        _pricelistId = pricelistId;
        _userId = userId;

        // mapped categories
        let categoryids = '';
        _selectedStore.pos_category_ids.forEach(categoryId => {
            categoryids += categoryId.id + ',';
        });
        _categoryId = categoryids.substring(0, categoryids.length - 1);

        _ajaxETA(store);
    },

    setTable(tableID) {
        // _selectedTable = _selectedStore.tables.filter((table)=>table.id === tableID)[0];
        _selectedTableId = tableID;
        _selectedEpochTime = null;
        this.emitSelectTable();
    },

    setType(type) {
        _type = type.toLowerCase();
    },

    emitChange() {
        this.emit(CHANGE_EVENT);
    },
    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    },
    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    },

    emitSelect() {
        this.emit(SELECT_EVENT);
    },
    addSelectListener(callback) {
        this.on(SELECT_EVENT, callback);
    },
    removeSelectListener(callback) {
        this.removeListener(SELECT_EVENT, callback);
    },

    emitSelectTable() {
        this.emit(SELECT_TABLE_EVENT);
    },
    addSelectTableListener(callback) {
        this.on(SELECT_TABLE_EVENT, callback);
    },
    removeSelectTableListener(callback) {
        this.removeListener(SELECT_TABLE_EVENT, callback);
    },

    emitSelectTime() {
        this.emit(SELECT_TIME_EVENT);
    },
    addSelectTimeListener(callback) {
        this.on(SELECT_TIME_EVENT, callback);
    },
    removeSelectTimeListener(callback) {
        this.removeListener(SELECT_TIME_EVENT, callback);
    },

    clear() {
        _selectedStore = null;
    },

    isTypeValid(store = _selectedStore, selectedEpochTime, timeETA, type = _type) {
        if (!store) {
            return {
                result: false,
                msgError: 'Please select a store',
            }
        }

        switch (type) {
            //   case 'takeaway':
            //     let check = _checkDatetimeTakeAway(store, selectedEpochTime, timeETA);
            //     if(check.result===false){
            //       return{
            //         result: false,
            //         msgError: check.message,
            //       }
            //     }
            //     return{result: true}
            case 'dinein':
                if (!_selectedTable || Object.keys(_selectedTable).length === 0) {
                    return {
                        result: false,
                        msgError: 'Please select a table'
                    }
                }
                return { result: true }
        }
    },
    setDatetime(datetime) {
        _selectedTable = {};
        _selectedEpochTime = datetime;
        _selectedDayTakeAway = _findSelectedDayTakeAway(_selectedStore, _selectedEpochTime);
        this.emitSelectTime();
    },

    checkDineInAvailable(store = _selectedStore) {
        let dayOfWeek = _findSelectedDayDineIn(store);
        _isDineInAvailable = _isDineInAvailableInTime(dayOfWeek);
        return _isDineInAvailable;
    },

    isDineInAvailable() {
        /*
        _ Used to hide Next button at the bottom if DineIn is not available
        */
        return _isDineInAvailable;
    },

    getPosStoreCode() {
        return _posStoreCode;
    },

    getCategoryId() {
        return _categoryId;
    },

    getConfigId() {
        return _configId;
    },

    getTableId() {
        return _tableId;
    },

    // getSuggestionId(){
    //     return _suggestionId;
    // },

    // getShowSuggestionOn() {
    //     return _showSuggestionOn;
    // },

    // getHomeSliderId() {
    //     return _homeSliderId;
    // },

    getQRToken() {
        return _token;
    },

    getSummaryIp() {
        return _summaryIp;
    },

    getSessionId() {
        return _sessionId;
    },

    getPriceListId() {
        return _pricelistId;
    },

    getUserId() {
        return _userId;
    },

    getTableNo() {
        let tableno = '';
        _selectedStore.floor_ids.forEach(floor => {
            if (floor.table_ids.filter((table) => table.id === parseInt(_selectedTableId)).length > 0) {
                tableno = floor.table_ids.filter((table) => table.id === parseInt(_selectedTableId))[0].name;
            }
        });
        return tableno
    }
})


StoreStore.dispatchData = AppDispatcher.register((action) => {
    switch (action.type) {
        case Constants.ActionTypes.AJAX_GET_STORE:
            _getData(action.storeID, action.callback);
            break;
        case Constants.ActionTypes.SELECT_STORE:
            StoreStore.setSelectedStore(action.store, action.posStoreCode, action.categoryId, action.token, action.summaryIp, action.sessionId, action.pricelistId, action.userId, action.configId, action.tableId);
            break;
        case Constants.ActionTypes.SELECT_TABLE:
            StoreStore.setTable(action.tableID);
            break;
        case Constants.ActionTypes.SELECT_DATETIME_TAKEAWAY:
            StoreStore.setDatetime(action.datetime);
            break;
        case Constants.ActionTypes.SET_BOOKING_TYPE:
            StoreStore.setType(action.bookingType);
            break;
    }
})

export default StoreStore;
