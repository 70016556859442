import React from 'react';
import {
  Text,
  View,
  Image,
  CheckBox,
  TouchableOpacity,
  ImageBackground,
  StyleSheet
} from 'react-native';

import * as Navigation from '../Routes/Routing';

import Config from '../Utils/Config';
import Device from '../Utils/Device';
import API from '../Utils/API';

import styleHelper from '../Styles/StyleHelper';
import ThemeStyles from '../Styles/ThemeStyles';

import OrderActions from '../Actions/OrderActions';

import StoreStore from '../Stores/StoreStore';

const styles = StyleSheet.create({
  customBtnStyle1: {
    maxHeight: Device.getSize(50),
  },
  customBtnStyle2: {
    maxHeight: Device.getSize(30),
  },
  customBtnSpacing: {
    marginTop: Device.getSize(10),
    marginBottom: Device.getSize(20),
  }
});

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.navigateHome = this.navigateHome.bind(this);
    this.state = {
      orderExist: false,
      isCheck: false
    }
  }

  componentDidMount() {
    if (Config.isProductionEnv) {
      API.getOrder(StoreStore.getQRToken(), {
        success: (res) => {
          if (res.lines.length > 0) {
            this.setState({
              orderExist: true
            })
          }
        },
        error: () => {

        }
      });
    }
  }

  navigateHome(orderType) {
    if (this.state.isCheck || Config.theme == "JJK") {
      OrderActions.setOrderType2(orderType);

      Navigation.navigate('Home');
    }
  }

  navigateMyOrder() {
    Navigation.navigate('MyOrder');
  }

  render() {
    let img_url = require('../Img/img_background_obbar.png');
    if (Config.theme == "JJK") {
      img_url = require('../Img/img_background_jjk.jpeg');
    }

    return (
      <ImageBackground
        source={img_url}
        resizeMode={'cover'}
        style={[styleHelper.backgroundImage]}>
        <View style={[styleHelper.flexCenter, ThemeStyles.tableNumberPosition(), { width: Device.width }]}>
          {
            Config.theme != "JJK" && (
              <Text style={[styleHelper.OrangeDarkFont, styleHelper.font14, styleHelper.fontHelveticaNeueRegular, styleHelper.flexCenter]}>
                {StoreStore.getSelectedStore().address.line1}
              </Text>
            )
          }

          <Text style={[ThemeStyles.tersierFontColor(), styleHelper.font16, styleHelper.fontBold, styleHelper.flexCenter, { marginTop: Device.getSize(40) }]}>
            {StoreStore.getTableNo()}
          </Text>
        </View>

        <View style={[{ width: Device.width, position: 'absolute', bottom: Device.getSize(20) }, styleHelper.flex1, styleHelper.flexCenter]}>
          <View style={[styleHelper.flexRow, styleHelper.flex1, styleHelper.flexCenter, { width: Device.width }]}>
            <TouchableOpacity
              onPress={() => { this.navigateHome('dinein') }}
              activeOpacity={Config.activeOpacity}
              style={[styles.customBtnStyle1, styleHelper.customBtn, ThemeStyles.primaryBtnColor(), ThemeStyles.customBtnBorder(), styleHelper.containerTouchableBig, styleHelper.flexCenter]}>

              <Text style={[styleHelper.fontHelveticaNeueBold, ThemeStyles.primaryBtnFontColor(), styleHelper.font12, styleHelper.textCenter]}>
                {this.state.orderExist ? 'ADD ORDER' : 'START ORDER'}
              </Text>
            </TouchableOpacity>
          </View>

          <TouchableOpacity
            onPress={() => { this.navigateMyOrder() }}
            activeOpacity={Config.activeOpacity}
            style={[styles.customBtnStyle2, styles.customBtnSpacing, styleHelper.customBtn, ThemeStyles.secondaryBtnColor(), ThemeStyles.customBtnBorder(), styleHelper.containerTouchableBig, styleHelper.flexCenter]}>

            <Text style={[styleHelper.fontHelveticaNeueBold, styleHelper.font12, styleHelper.textCenter, ThemeStyles.secondaryBtnFontColor(),]}>
              {Config.theme == "OBBAR" && 'MY ORDER' }
              {Config.theme == "JJK" && 'ORDER SUMMARY' }
            </Text>

          </TouchableOpacity>

          {
            Config.theme == "OBBAR" &&
            <View style={[styleHelper.flexRow, styleHelper.flexCenter, { width: Device.width * 0.7, marginBottom: Device.getSize(20) }]}>
              <CheckBox
                value={this.state.isCheck}
                onValueChange={() => this.setState({ isCheck: !this.state.isCheck })}
                style={[{ marginRight: Device.getSize(5) }]}
              />
              <Text style={[styleHelper.fontHelveticaNeueBold, styleHelper.font12, { color: '#F5DBD3' }]}>
                I confirm I am of legal drinking age, ie aged 18 and above.
              </Text>
            </View>
          }

        </View>
      </ImageBackground>
    );
  }
}
