import React from 'react';
import {View, Platform, ActivityIndicator} from 'react-native';

import styleHelper from '../Styles/StyleHelper';
import Storage from '../Utils/Storage';

export default class Spinner extends React.Component {
  state = {
    isShowed: false,
  };

  componentDidMount() {
    Storage.Components.Spinner = this;
  }

  show() {
    this.setState({
      isShowed: true,
    });
  }

  hide() {
    this.setState({
      isShowed: false,
    });
  }

  render() {
    if (this.state.isShowed === true) {
      switch (Platform.OS) {
        case 'android':
          return (
            <View style={[styleHelper.overlayBlack, styleHelper.flexCenter]}>
              <ActivityIndicator
                animating={true}
                color="#38BB9E"
                style={{transform: [{scale: 1.7}]}}
                size="large"
              />
            </View>
          );
        case 'ios':
          return (
            <View style={[styleHelper.overlayBlack, styleHelper.flexCenter]}>
              <ActivityIndicator
                animating={true}
                color="white"
                style={{flex: 1, alignSelf: 'stretch'}}
                size="large"
              />
            </View>
          );
        case 'web':
        return (
            <View style={[styleHelper.overlayBlack, styleHelper.flexCenter]}>
            <ActivityIndicator
                animating={true}
                color="white"
                style={{flex: 1, alignSelf: 'stretch'}}
                size="large"
            />
            </View>
        );
        default:
          return null;
      }
    }
    return null;
  }
}
