import React from 'react';
import { View, TouchableOpacity, Image } from 'react-native';

import styleHelper from '../Styles/StyleHelper';

import Config from '../Utils/Config';

const IconMinus = (props) => {
    if (props.disabled === true) {
        return (
            <View style={{ opacity: 0.5 }}>
                <ImageIconMinus />
            </View>
        )
    }

    return (
        <TouchableOpacity
            onPress={props.onPress}
            activeOpacity={Config.activeOpacity}>
            <ImageIconMinus />
        </TouchableOpacity>
    )
}

const ImageIconMinus = () => {
    return (
        <Image
            source={require('../img/ic_minus_black.png')}
            style={styleHelper.bitsmallIconSize}
            resizeMode={'contain'} />
    )
}

export default IconMinus;