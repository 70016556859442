import AppDispatcher from '../Dispatcher/AppDispatcher';
import Constants from '../Constants/AppConstants';

const StoreActions = {
	getData(storeID, callback) {
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.AJAX_GET_STORE,
            storeID: storeID,
            callback: callback
        })
    },
    select(store, posStoreCode, categoryId, token, summaryIp, sessionId, pricelistId, userId, configId, tableId) {
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.SELECT_STORE,
            store: store,
            posStoreCode: posStoreCode,
            categoryId: categoryId,
            token: token,
            summaryIp: summaryIp,
            sessionId: sessionId,
            pricelistId: pricelistId,
            userId: userId,
            configId: configId,
            tableId: tableId,
        })
    },
    selectTable(tableID){
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.SELECT_TABLE,
            tableID: tableID
        })
    },
    selectDatetime(datetime){
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.SELECT_DATETIME_TAKEAWAY,
            datetime: datetime
        })
    },
    setType(bookingType){
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.SET_BOOKING_TYPE,
            bookingType: bookingType
        })
    }
}

export default StoreActions;
