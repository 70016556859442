import AppDispatcher from '../Dispatcher/AppDispatcher';
import Constants from '../Constants/AppConstants';

const MenuActions = {
	getData(storeID, callback) {
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.AJAX_GET_MENU,
            storeID: storeID,
            callback
        })
    },
    updateMenuSection(callback) {
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.UPDATE_MENU_SECTIONS,
            callback
        })
    },
    initMenu(categories, callback){
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.INIT_MENU,
            categories: categories,
            callback
        })
    },
    setCustomModifierMapping(modifierMap, callback){
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.SET_MODIFIER_MAP,
            modifierMap: modifierMap,
            callback
        })
    },
}

export default MenuActions;
