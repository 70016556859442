import React from 'react';
import { Text, View } from 'react-native';

import styleHelper from '../Styles/StyleHelper';

import ExtraCharge from './ExtraCharge';

import Services from '../Utils/Services';
import Device from '../Utils/Device';

const ProductOrder = props => {
  return (
    <View>
      {props.order.items.map((product, key) => (
        <View
          key={key}
          style={[
            styleHelper.containerVeryTiny,
            { paddingHorizontal: Device.getSize(10), marginBottom: Device.getSize(10) }
          ]}>
          <View
            style={[
              styleHelper.flexRowSpaceBetweenAlignTop,
              styleHelper.containerVerticalSmall,
            ]}>
            <Text
              style={[
                styleHelper.flex1,
                styleHelper.fontRegular,
                styleHelper.font16,
                styleHelper.fontBlack,
              ]}>
              {product.quantity}x {product.productName}
            </Text>
            <Text
              style={[
                styleHelper.fontRegular,
                styleHelper.font16,
                styleHelper.fontBlack,
              ]}>
              {Services.formatPrice(product.productPrice)}
            </Text>
          </View>

          <ProductVariant product={product} />
          <Modifiers modifiers={product.modifiers} />
        </View>
      ))}

      <Subtotal subTotal={props.subTotal} />

      <Discount discount={props.discount} />

      <DiscountProducts discount_products={props.discount_products} />

      <ExtraCharge extraCharges={props.order.extraCharges} />

      <Total total={props.total} />
    </View>
  );
};

const ProductVariant = props => {
  if (!props.product.variantName) {
    return null;
  }

  return (
    <View style={[styleHelper.flexRowSpaceBetweenAlignTop]}>
      <Text
        style={[
          styleHelper.flex1,
          styleHelper.fontRegular,
          styleHelper.font13,
          styleHelper.fontBlack,
        ]}>
        {props.product.variantName}
      </Text>

      <Text
        style={[
          styleHelper.fontRegular,
          styleHelper.font13,
          styleHelper.fontBlack,
        ]}>
        {Services.formatPrice(props.product.variantPrice)}
      </Text>
    </View>
  );
};

const Modifiers = props => {
  if (!props.modifiers || props.modifiers.length === 0) {
    return null;
  }

  return (
    <View>
      {props.modifiers.map((modifier, key) => (
        <View
          key={key}
          style={[
            styleHelper.flexRowSpaceBetweenAlignTop,
            styleHelper.containerVeryXTiny,
          ]}>
          <Text
            style={[
              styleHelper.flex1,
              styleHelper.fontRegular,
              styleHelper.font12,
              styleHelper.fontBlack,
            ]}>
            {modifier.quantity}x {modifier.modifierName}
          </Text>
          <Text
            style={[
              styleHelper.fontRegular,
              styleHelper.font12,
              styleHelper.fontBlack,
            ]}>
            {Services.formatPrice(modifier.price)}
          </Text>
        </View>
      ))}
    </View>
  );
};

const Subtotal = props => {
  return (
    <View
      style={[
        styleHelper.customContainerNormal4,
        styleHelper.flexRowSpaceBetween,
        styleHelper.borderTopBlackTransparent,
      ]}>
      <Text
        style={[
          styleHelper.font15,
          styleHelper.fontBlack,
          styleHelper.fontRegular,
        ]}>
        Subtotal
      </Text>

      <Text
        style={[
          styleHelper.fontBlack,
          styleHelper.font16,
          styleHelper.fontRegular,
        ]}>
        {Services.formatPrice(props.subTotal)}
      </Text>
    </View>
  );
};

const DiscountProducts = props => {
  return (
    <View style={[styleHelper.customContainerNormal3, styleHelper.borderBottomBlackTransparent]}>
      {props.discount_products.map((product, key) => (
        <View
          key={key}
          style={[
            styleHelper.containerVeryTiny,
            { paddingHorizontal: Device.getSize(10), marginBottom: Device.getSize(10) }
          ]}>
          <View
            style={[
              styleHelper.flexRowSpaceBetweenAlignTop,
              styleHelper.containerVerticalSmall,
            ]}>
            <Text
              style={[
                styleHelper.flex1,
                styleHelper.fontRegular,
                styleHelper.font16,
                styleHelper.fontBlack,
              ]}>
              {product.quantity}x {product.name}
            </Text>
            <Text
              style={[
                styleHelper.fontRegular,
                styleHelper.font16,
                styleHelper.fontBlack,
              ]}>
              {Services.formatPrice(product.discount)}
            </Text>
          </View>
  
          {/* <ProductVariant product={product} /> */}
          {/* <Modifiers modifiers={product.modifiers} /> */}
        </View>
      ))}
    </View>
  )
}

const Discount = (props) => {
  if (!props.discount || props.discount == 0) {
    return <View />;
  }

  return (
    <View
      style={[
        styleHelper.customContainerNormal3,
        styleHelper.flexRowSpaceBetween,
        styleHelper.borderTopBlackTransparent,
      ]}>
      <Text
        style={[
          styleHelper.font15,
          styleHelper.fontBlack,
          styleHelper.fontRegular,
        ]}>
        Discount
      </Text>

      <Text
        style={[
          styleHelper.fontBlack,
          styleHelper.font16,
          styleHelper.fontRegular,
        ]}>
        {Services.formatPrice(props.discount)}
      </Text>
    </View>
  );
}

const Total = props => {
  return (
    <View
      style={[
        styleHelper.customContainerNormal4,
        styleHelper.flexRowSpaceBetween,
        styleHelper.borderTopBlackTransparent,
        { marginTop: Device.getSize(10) }
      ]}>
      <Text
        style={[
          styleHelper.font15,
          styleHelper.fontBlack,
          styleHelper.fontRegular,
        ]}>
        Grand Total
      </Text>
      <Text
        style={[
          styleHelper.fontBlack,
          styleHelper.font16,
          styleHelper.fontRegular,
        ]}>
        {Services.formatPrice(props.total)}
      </Text>
    </View>
  );
};

export default ProductOrder;
