import React from 'react';
import { Text, View, ScrollView, TouchableOpacity } from 'react-native';

import * as Navigation from '../Routes/Routing';

import styleHelper from '../Styles/StyleHelper';
import ThemeStyles from '../Styles/ThemeStyles';

import ImageBackground from '../Components/ImageBackground';
import OrderInfo from '../Components/OrderInfo';
import SmallSpinner from '../Components/SmallSpinner';

import Config from '../Utils/Config';
import Device from '../Utils/Device';

import CartActions from '../Actions/CartActions';

import CartStore from '../Stores/CartStore';

export default class Confirmation extends React.Component {
  constructor(props) {
    super(props);

    this.resetCart = this.resetCart.bind(this);
    this.renderDone = this.renderDone.bind(this);
    this.renderTopNav = this.renderTopNav.bind(this);

    this.state = {
      grandTotal: CartStore.getGrandTotal(),
      subTotal: CartStore.getSubtotal(),
      order: CartStore.getOrder(),
      showDone: true,
      smallSpinner: false,
    };
  }

  resetCart(callback) {
    CartActions.reset();
    callback();
  }

  renderTopNav() {
    return (
      <View style={[styleHelper.flexRow, ThemeStyles.primaryBgColor(), { height: Device.getSize(50) }]}>
        <View style={[styleHelper.flex1]} />

        <View style={[styleHelper.flex1, styleHelper.flexCenter]}>
          <Text style={[styleHelper.fontWhite, styleHelper.font16, styleHelper.fontHelveticaNeueBold]}>
            CONFIRMATION
          </Text>
        </View>

        <View style={[styleHelper.flex1]} />
      </View>
    )
  }

  renderDone() {
    return (
      <View
        style={[styleHelper.flexRow, styleHelper.flexCenter, ThemeStyles.secondaryBgColor(),
        { borderRadius: Device.getSize(5), marginTop: Device.getSize(10), marginHorizontal: Device.getSize(10) }
        ]}>
        <TouchableOpacity
          onPress={() => this.resetCart(() => {
            Navigation.navigate('Welcome');
          })}
          activeOpacity={Config.activeOpacity}
          style={[styleHelper.containerNormal, styleHelper.flex1, styleHelper.flexRow, styleHelper.flexCenter]}>
          <Text
            style={[styleHelper.fontTitleBold, styleHelper.fontWhite, styleHelper.font16, styleHelper.textCenter]}>
            DONE
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  render() {
    return (
      <View style={styleHelper.flex1}>
        {this.renderTopNav()}
        <ImageBackground>
          <View style={[{ padding: Device.getSize(10) }]}>
            <ScrollView style={[{ minHeight: Device.height * 0.83 }]}>
              <SectionBlackTop
                grandTotal={this.state.grandTotal}
                order={this.state.order}
              />
            </ScrollView>
          </View>

          {
            this.state.showDone &&
            this.renderDone()
          }
        </ImageBackground>
      </View>
    );
  }
}

const SectionBlackTop = props => {
  return (
    <View>
      <OrderInfo />

      <View
        style={[styleHelper.containerNormal]}>
        <Text
          style={[styleHelper.fontBlack, styleHelper.font17, styleHelper.fontRegular, styleHelper.textCenter, styleHelper.containerVerticalTiny]}>
          We've got your order
        </Text>

        <Text
          style={[styleHelper.fontBlack, styleHelper.font17, styleHelper.fontRegular, styleHelper.textCenter, styleHelper.containerVerticalTop]}>
          and will be serving you shortly
        </Text>
      </View>

    </View>
  );
};
