import React from 'react';
import {StyleSheet} from 'react-native';

import Device from '../Utils/Device';
import Config from '../Utils/Config';
import Colors from './Colors';

const fontHelveticaNeue = {
  regular: 'HelveticaNeue-Light',
  bold: 'HelveticaNeue-Bold'
};

const HEIGHT_INPUT = Device.getSize(48);

const StyleHelper = StyleSheet.create({
  // CONTAINERS
  containerTopFit: {
    paddingTop: Config.heightNavBar,
    flex: 1,
    position: 'relative'
  },
  containerHeightFit: {
    height:  Config.heightNavBar
  },
  backgroundImage: {
    width: Device.width,
    height: Device.height,
    flex: 1
  },
  imageIcon: {
    padding: Device.getSize(30)
  },
  containerTiny: {
    paddingVertical: Device.getSize(5),
    paddingHorizontal: Device.getSize(12)
  },
  containerSmall: {
    paddingVertical: Device.getSize(10),
    paddingHorizontal: Device.getSize(12)
  },
  customContainerNormal: {
    paddingVertical: Device.getSize(5),
    paddingHorizontal: Device.getSize(10),
  },
  customContainerNormal2: {
    paddingTop: Device.getSize(5),
    paddingHorizontal: Device.getSize(10),
  },
  customContainerNormal3: {
    paddingTop: Device.getSize(10),
    paddingHorizontal: Device.getSize(10),
  },
  customContainerNormal4: {
    paddingVertical: Device.getSize(10),
    paddingHorizontal: Device.getSize(10),
  },
  containerNormal: {
    paddingVertical: Device.getSize(15),
    paddingHorizontal: Device.getSize(10)
  },
  containerCustom: {
    paddingVertical: Device.getSize(15),
    paddingLeft: Device.getSize(10),
    paddingRight: Device.getSize(20)
  },
  containerLarge: {
    paddingVertical: Device.getSize(25),
    paddingHorizontal: Device.getSize(10)
  },
  containerMiddle: {
    paddingVertical: Device.getSize(200),
    paddingHorizontal: Device.getSize(25)
  },
  containerVertical2: {
    paddingTop: Device.getSize(15),
  },
  containerVerticalXTiny: {
    paddingVertical: Device.getSize(1),
  },
  containerVerticalVTiny: {
    paddingVertical: Device.getSize(3),
  },
  containerVerticalTiny: {
    paddingVertical: Device.getSize(5),
  },
  containerVerticalSmall: {
    paddingVertical: Device.getSize(10),
  },
  containerVerticalSmall2: {
    paddingVertical: Device.getSize(5),
  },
  containerVerticalNormal: {
    paddingVertical: Device.getSize(15),
  },
  containerHorizontalTiny: {
    paddingHorizontal: Device.getSize(5),
  },
  containerHorizontalSmall: {
    paddingHorizontal: Device.getSize(10),
  },
  containerHorizontalNormal: {
    paddingHorizontal: Device.getSize(15),
  },
  containerTouchableBig: {
    paddingVertical: Device.getSize(14),
  },
  containerFullHeight: {
    height: Device.height
  },
  heightScrollViewContent: {
    height: Device.height - Config.heightNavBar
  },
  containerText: {
    marginVertical : Device.getSize(2)
  },
  containerTitle: {
    marginBottom: Device.getSize(5)
  },
  containerBigSection: {
    minHeight: Device.getSize(85)
  },
  containerMarginFullWidth: {
    marginHorizontal: Device.getSize(-10),
  },
  containerNormalXSmallIcon: {
    paddingVertical: Device.getSize(6),
  },
  containerNormalSmallIcon: {
    paddingVertical: Device.getSize(12),
    paddingHorizontal: Device.getSize(10)
  },
  containerMarginTopSmall: {
    marginTop: Device.getSize(10),
  },
  containerMarginTopNormal: {
    marginTop: Device.getSize(20),
  },
  containerMarginTopLarge: {
    marginTop: Device.getSize(50),
  },
  containerMarginBottomSmall: {
    marginBottom: Device.getSize(10),
  },
  containerNumber: {
    paddingVertical: Device.getSize(5),
    width: Device.getSize(22),
    marginHorizontal: Device.getSize(6),
  },

  //top-right number of cart quantity
  containerNotification: {
    width: Device.getSize(12),
    height: Device.getSize(12),
    borderRadius: Device.getSize(8),
    position: 'absolute',
    top: Device.getSize(-2),
    // left: Device.getSize(0),
    right: Device.getSize(-4),
    alignItems: 'center',
    paddingTop: Device.getSize(1),
  },
  containerDeviceHeight: {
    height: Device.height,
  },

  //INPUTS
  inputWhiteRound: {
    height: Device.isIOS === true ? Device.getSize(30):Device.getSize(40),
    borderRadius: Device.getSize(4),
    backgroundColor: Colors.white,
    paddingHorizontal: Device.getSize(6),
    color: Colors.blackSecond,
    fontFamily: fontHelveticaNeue.bold,
  },
  inputLoupe: {
    paddingLeft: Device.getSize(27)
  },
  iconLoupeInput: {
    width: Device.getSize(28),
    height: Device.getSize(28),
    position: 'absolute',
    left: Device.getSize(12),
    top: Device.isIOS === true ? Device.getSize(11):Device.getSize(15),
  },
  inputWhite: {
    // height: HEIGHT_INPUT,
    height: Device.getSize(25),
    backgroundColor: Colors.white,
    paddingHorizontal: Device.getSize(12),
    paddingVertical: Device.getSize(5),
    // paddingVertical: Device.getSize(15),
    fontSize: Device.getSize(10),
    // fontSize: Device.getSize(15),
    // fontFamily: fontHelveticaNeue.bold,
    borderColor: Colors.grey,
    borderWidth: Device.getSize(1),
    borderStyle: 'solid'
  },
  inputWhiteLeftIconReal: {
    height: HEIGHT_INPUT,
    backgroundColor: Colors.white,
    paddingRight: Device.getSize(12),
    color: Colors.blackSecond,
    fontFamily: fontHelveticaNeue.bold,
    paddingLeft: Device.getSize(50),
    fontSize: Device.getSize(15)
  },
  inputWhiteLeftIcon: {
    height: HEIGHT_INPUT,
    backgroundColor: Colors.white,
    paddingRight: Device.getSize(12),
    color: Colors.blackSecond,
    fontFamily: fontHelveticaNeue.bold,
    paddingLeft: Device.getSize(83),
    fontSize: Device.getSize(15)
  },
  inputWhiteRightIcon: {
    height: HEIGHT_INPUT,
    backgroundColor: Colors.white,
    paddingLeft: Device.getSize(12),
    color: Colors.blackSecond,
    fontFamily: fontHelveticaNeue.bold,
    paddingRight: Device.getSize(48),
    fontSize: Device.getSize(15)
  },
  inputTransparent: {
    height: HEIGHT_INPUT,
    color: Colors.white,
    backgroundColor: 'transparent',
    fontFamily: fontHelveticaNeue.bold,
    fontSize: Device.getSize(15),
    paddingHorizontal: Device.getSize(12),
  },
  iconInputLeft: {
    width: Device.getSize(28),
    height: Device.getSize(28),
    position: 'absolute',
    left: Device.getSize(12),
    top: Device.getSize(12),
  },
  textInputLeft: {
    width: Device.getSize(33),
    height: Device.getSize(28),
    textAlign: 'right',
    position: 'absolute',
    left: Device.getSize(50),
    top: Device.getSize(14),
    fontSize: Device.getSize(15)
  },
  iconInputRight: {
    width: Device.getSize(28),
    height: Device.getSize(28),
    position: 'absolute',
    right: Device.getSize(12),
    top: Device.getSize(10),
  },
  inputBorderRadius: {
    borderRadius: Device.getSize(3),
  },
  //BORDERSS
  borderTopWhite: {
    borderStyle: 'solid',
    borderTopColor: Colors.white,
    borderTopWidth: Device.getSize(1),
  },
  borderBottomWhite: {
    borderStyle: 'solid',
    borderBottomColor: Colors.white,
    borderBottomWidth: Device.getSize(1),
  },
  borderTopBlackTransparent: {
    borderStyle: 'solid',
    borderTopColor: Colors.blackTransparent,
    borderTopWidth: Device.getSize(1),
  },
  borderBottomBlackTransparent: {
    borderStyle: 'solid',
    borderBottomColor: Colors.blackTransparent,
    borderBottomWidth: Device.getSize(1),
  },
  borderBottomWhiteTransparent: {
    borderStyle: 'solid',
    // borderBottomColor: Colors.whiteTransparent,
    borderBottomColor: Colors.greyTransparent,
    borderBottomWidth: Device.getSize(1),
  },
  borderTopWhiteTransparent: {
    borderStyle: 'solid',
    // borderTopColor: 'rgba(255,255,255,0.7)',
    borderTopColor: 'rgba(0,0,0,0.7)',
    borderTopWidth: Device.getSize(1),
  },
  borderTopWhite2: {
    borderStyle: 'solid',
    // borderTopColor: Colors.white,
    borderTopColor: Colors.black,
    borderTopWidth: Device.getSize(2),
  },
  borderBottomWhite2: {
    borderStyle: 'solid',
    // borderBottomColor: Colors.white,
    borderBottomColor: Colors.black,
    borderBottomWidth: Device.getSize(2),
  },
  borderVerticalWhite2: {
    borderStyle: 'solid',
    // borderTopColor: Colors.white,
    borderTopColor: Colors.black,
    borderTopWidth: Device.getSize(2),
    // borderBottomColor: Colors.white,
    borderBottomColor: Colors.black,
    borderBottomWidth: Device.getSize(2),
  },
  borderVerticalWhite: {
    borderStyle: 'solid',
    // borderTopColor: Colors.white,
    borderTopColor: Colors.black,
    borderBottomColor: Colors.black,
    borderTopWidth: Device.getSize(1),
    // borderBottomColor: Colors.white,
    borderBottomWidth: Device.getSize(1),
  },
  borderVerticalWhiteTransparent: {
    borderStyle: 'solid',
    borderTopColor: Colors.blackTransparent,
    borderBottomColor: Colors.blackTransparent,
    // borderTopColor: Colors.whiteTransparent,
    borderTopWidth: Device.getSize(1),
    // borderBottomColor: Colors.whiteTransparent,
    borderBottomWidth: Device.getSize(1),
  },
  borderLeftWhiteTransparent: {
    borderStyle: 'solid',
    borderLeftColor: Colors.whiteTransparent,
    borderLeftColor: Colors.blackTransparent,
    borderLeftWidth: Device.getSize(1),
  },
  borderBottomWhite: {
    borderStyle: 'solid',
    borderBottomColor: Colors.white,
    borderBottomColor: Colors.black,
    borderBottomWidth: Device.getSize(1),
  },
  borderTopRed: {
    borderStyle: 'solid',
    borderTopColor: Colors.red,
    borderTopWidth: Device.getSize(1),
  },
  borderGrey: {
    borderStyle: 'solid',
    borderColor: '#EEEEEE',
    borderWidth: Device.getSize(1),
  },
  borderDarkGreen: {
    borderStyle: 'solid',
    borderColor: '#1a3b49',
    borderWidth: Device.getSize(1),
  },
  DarkPinkBorder: {
    borderColor: Config.isTss ? Colors.DarkBlue : Config.isJjk ? Colors.JjkLightGreen : Colors.DarkPink
  },
  OrangeDarkBorder: {
    borderColor: Config.isTss ? Colors.Orange : Config.isJjk ? Colors.JjkLightGreen : Colors.DarkGreen,
    borderStyle: 'solid',
    borderWidth: Device.getSize(1),
  },
  // BGS
  bgBlack: {
    // backgroundColor: Colors.black
    backgroundColor: Colors.white
  },
  bgBlackTransparent: {
    // backgroundColor: 'rgba(0,0,0,0.7)',
    backgroundColor: 'rgba(255,255,255,0.7)',
  },
  bgBlackSecond: {
    backgroundColor: Colors.blackSecond
  },
  bgBlackSecondDark: {
    backgroundColor: Colors.blackSecondDark
  },
  bgLightGrey: {
    backgroundColor: Colors.greyDark
  },
  bgSoupOrange: {
    backgroundColor: Colors.soupOrange
  },
  bgWhite: {
    backgroundColor: Colors.white
  },
  bgGrey: {
    backgroundColor: Colors.grey
  },
  bgOrange: {
    backgroundColor: Colors.Orange
  },
  bgPink: {
    backgroundColor: '#FFD3C3'
  },
  bgDarkGreen: {
    backgroundColor: '#1A3B49'
  },
  bgRed: {
    backgroundColor: Colors.red
  },
  bgTransparent: {
    backgroundColor: 'rgba(0,0,0,0)'
  },
  OrangeDarkBg: {
    backgroundColor: Config.isTss ? Colors.Orange : Config.isJjk ? Colors.JjkGreen : Colors.DarkGreen
  },
  HeavyOrangeDarkBg: {
    backgroundColor: Config.isTss ? Colors.HeavyOrange : Config.isJjk ? Colors.JjkGreen : Colors.DarkGreen
  },
  LightOrangeDarkBg: {
    backgroundColor: Config.isTss ? Colors.LightOrange : Config.isTgk ? Colors.TgkLightGrey : Config.isJjk ? Colors.JjkGreen : Colors.DarkGreen
  },
  OrangePinkBg: {
    backgroundColor: Config.isTss ? Colors.Orange : Config.isTgk ? Colors.white : Config.isJjk ? 'rgba(0, 0, 0, 0)' : Colors.LightPink
  },
  LightOrangePinkBg: {
    backgroundColor: Config.isTss ? Colors.LightOrange : Config.isTgk ? Colors.TgkLightGrey : Colors.LightPink
  },
  PinkBg: {
    backgroundColor: Config.isTss ? Colors.Pink : Colors.LightPink
  },
  BlueGreyBg: {
    backgroundColor: Config.isTss ? '#000647' : Config.isTgk ? Colors.TgkGrey : '#000647'
  },
  OrangeGreyBg: {
    backgroundColor: Config.isTss ? Colors.Orange : Config.isTgk ? Colors.TgkGrey : Config.isJjk ? Colors.JjkGreen : Colors.Orange
  },
  // FONTS
  OrangeDarkFont: {
    color: Config.isTss ? Colors.Orange : Config.isTgk ? Colors.TgkBlue : Config.isJjk ? Colors.JjkGreen : Colors.DarkGreen
  },
  PinkGreenFont: {
    color: Config.isTss ? Colors.Pink : Config.isTgk ? Colors.Green : Config.isJjk ? Colors.JjkGold : Colors.Green
  },
  VeryGreyFont: {
    color: Colors.greyTransparent
  },
  DarkFont: {
    color: Config.isTss ? Colors.DarkBlue : Config.isTgk ? Colors.DarkGreen : Config.isJjk ? Colors.JjkGreen : Colors.DarkGreen
  },
  DarkPinkFont: {
    color: Config.isTss ? Colors.DarkBlue : Config.isTgk ? Colors.DarkPink : Config.isJjk ? Colors.JjkGold : Colors.DarkPink
  },
  WhiteDarkFont: {
    color: Config.isTss ? Colors.white : Config.isTgk ? Colors.DarkGreen : Config.isJjk ? Colors.JjkGold : Colors.DarkGreen
  },
  OrangePinkFont: {
    color: Config.isTss ? Colors.Orange : Config.isTgk ? Colors.TgkRed : Config.isJjk ? Colors.JjkGreen : Colors.LightPink
  },
  OrangeBlackFont: {
    color: Config.isTss ? Colors.Orange : Config.isTgk ? Colors.black : Config.isJjk ? Colors.JjkGreen : Colors.LightPink
  },
  GrayGreenFont: {
    color: Config.isTss ? Colors.Gray : Config.isTgk ? Colors.Green : Config.isJjk ? Colors.JjkGold : Colors.Green
  },
  RedGreenFont: {
    color: Config.isTss ? Colors.red : Config.isTgk ? Colors.Green : Config.isJjk ? Colors.JjkGold : Colors.Green
  },
  BlackBlueFont: {
    color: Config.isTss ? Colors.black : Config.isTgk ? Colors.TgkBlue : Config.isJjk ? Colors.JjkGreen : Colors.black
  },
  BlackGreyFont: {
    color: Config.isTss ? Colors.black : Config.isTgk ? Colors.TgkGrey : Config.isJjk ? Colors.JjkGreen : Colors.black
  },
  DarkRedFont: {
    color: Config.isTss ? Colors.HeavyOrange : Config.isTgk ? Colors.TgkRed : Config.isJjk ? Colors.JjkLightGreen : Colors.DarkGreen
  },
  WhiteGreyFont:{
    color: Config.isTss ? Colors.white : Config.isTgk ? Colors.TgkGrey : Config.isJjk ? Colors.JjkLightGreen : Colors.white
  },
  DarkGreenFont:{
    color: Config.isJjk ? Colors.JjkDarkGreen : Colors.black
  },
  fontWhite: {
    color: Colors.white
  },
  fontWhiteGold: {
    color: (Config.isTss || Config.isTgk) ? Colors.white : Config.isJjk ? Colors.JjkGold : Colors.white
  },
  fontRed: {
    color: '#F00'
  },
  fontWhiteTransparent: {
    color: Colors.whiteTransparent,
  },
  fontOrange: {
    color: Colors.Orange
  },
  fontRed: {
    color: Colors.red
  },
  fontBlack: {
    color: Colors.black
  },
  greyFont: {
    color: Colors.grey
  },
  fontGrey: {
    color: Colors.greyDark
  },
  fontBlackReal: {
    color: Colors.black,
  },
  fontBlackSecond: {
    color: Colors.blackSecond,
  },
  fontBebasNeueBold: {
    fontFamily: fontHelveticaNeue.Bold
  },
  fontHelveticaNeueRegular: {
    fontFamily: fontHelveticaNeue.regular
  },
  fontHelveticaNeueBold: {
    fontFamily: fontHelveticaNeue.bold
  },
  fontRegular: {
    fontFamily: fontHelveticaNeue.bold
  },
  fontBold: {
    fontFamily: fontHelveticaNeue.bold
  },
  fontTitleRegular: {
    fontFamily: fontHelveticaNeue.bold
  },
  fontTitleBold: {
    fontFamily: fontHelveticaNeue.bold
  },
  fontRalewayBold: {
    fontFamily: fontHelveticaNeue.bold
  },
  font8: {
    fontSize: Device.getSize(8)
  },
  font9: {
    fontSize: Device.getSize(9)
  },
  font10: {
    fontSize: Device.getSize(10)
  },
  font11: {
    fontSize: Device.getSize(11)
  },
  font12: {
    fontSize: Device.getSize(12)
  },
  font13: {
    fontSize: Device.getSize(13)
  },
  font14: {
    fontSize: Device.getSize(14)
  },
  font15: {
    fontSize: Device.getSize(15)
  },
  font16: {
    fontSize: Device.getSize(16)
  },
  font17: {
    fontSize: Device.getSize(17)
  },
  font18: {
    fontSize: Device.getSize(18)
  },
  font19: {
    fontSize: Device.getSize(19)
  },
  font20: {
    fontSize: Device.getSize(20)
  },
  font22: {
    fontSize: Device.getSize(22)
  },
  font23: {
    fontSize: Device.getSize(23)
  },
  font25: {
    fontSize: Device.getSize(25)
  },
  font30: {
    fontSize: Device.getSize(30)
  },
  font40: {
    fontSize: Device.getSize(40)
  },
  fontPink: {
    color: '#FFD3C3'
  },
  fontLightGreen: {
    color: '#B4D4BC'
  },
  fontDarkGreen: {
    color: '#1a3b49'
  },
  fontXDark: {
    color: Colors.xdark,
  },
  fontErrorWhite: {
    fontFamily: fontHelveticaNeue.bold,
    color: Colors.white,
    fontSize: Device.getSize(12),
  },
  textCenter: {
    alignSelf: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },

  // OVERLAYS

  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10
  },

  // Android picker does not change options when using zIndex
  overlayNoZIndex: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  overlayBlack: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  overlayInputDisabled: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(204, 204, 204, 0.6)'
  },
  // STATUSBARS
  orangeStatusBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: Config.heightStatusBar,
    backgroundColor: Colors.Orange
  },

  // LAYOUTS
  flex1: {
    flex: 1
  },
  flex2: {
    flex: 2
  },
  flex3: {
    flex: 3
  },
  flexCenter: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  flexMiddle: {
    alignItems: 'center'
  },
  flexRight: {
    alignItems: 'flex-end',
  },
  flexRow: {
    flexDirection: 'row'
  },
  flexRowTop: {
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  flexColumn: {
    flexDirection: 'column'
  },
  flexJustifyEnd: {
    justifyContent: 'flex-end'
  },
  flexSpaceBetween: {
    justifyContent: 'space-between'
  },
  alignSelfStretch: {
    alignSelf: 'stretch'
  },
  alignSelfCenter: {
    alignSelf: 'center'
  },
  flexRowSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  flexRowSpaceBetweenAlignTop: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  fullHeight: {
    height: Device.height
  },
  // NAVBARS
  navBarTransparent: {
  },
  navBarOrange: {
    backgroundColor: Config.isTss ? Colors.Orange : Config.isTgk ? Colors.TgkGrey : Config.isJjk ? Colors.JjkGreen : Colors.LightPink,
    // height: Device.getSize(64),
    height: Device.getSize(44),
    // height: Device.getSize(84),
  },
  navBarFake: {
    height: Device.getSize(64),
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
  },
  //TOUCHABLES
  touchableNavLeft: {
    marginLeft: Device.getSize(10),
    marginRight: Device.getSize(50),
    // marginTop: Device.isIOS === true ? Device.getSize(6): Device.getSize(-2)
    marginTop: Device.isIOS === true ? Device.getSize(-2): Device.getSize(-2)
  },
  touchableNavRight: {
    marginRight: Device.getSize(10),
    marginLeft: Device.getSize(50),
    // marginTop: Device.isIOS === true ? Device.getSize(6): Device.getSize(-2)
    marginTop: Device.isIOS === true ? Device.getSize(-2): Device.getSize(-2)
  },
  touchableNavRightCartPlus: {
    marginTop: Device.isIOS === true ? Device.getSize(4): Device.getSize(-4)
  },
  touchableRightTiny: {
    width: Device.getSize(45),
  },
  touchableRightSmall: {
    width: Device.getSize(75),
  },
  //LOGOS
  logoBig: {
    width: Device.getSize(120),
    height: Device.getSize(120),
  },
  // MODALS
  containerModalCenter: {
    borderRadius: Device.getSize(5),
    alignSelf: 'stretch',
    marginHorizontal: Device.getSize(30)
  },
  containerPaddingModalContent: {
    paddingVertical: Device.getSize(20),
    paddingHorizontal: Device.getSize(20)
  },
  // OTHERS
  titleNav: {
    // marginTop: Device.isIOS === true ? Device.getSize(10): Device.getSize(0)
    marginTop: Device.getSize(0)
  },
  imgTitle: {
    width: Device.getSize(150),
    height: Device.getSize(25),
    marginTop: Device.isIOS === true ? Device.getSize(7): 0
  },
  xsmallIconSize: {
    width: Device.getSize(12),
    height: Device.getSize(12),
  },
  verysmallIconSize: {
    width: Device.getSize(17),
    height: Device.getSize(17),
  },
  bitsmallIconSize: {
    width: Device.getSize(23),
    height: Device.getSize(23),
  },
  smallIconSize: {
    width: Device.getSize(25),
    height: Device.getSize(25),
  },
  mediumIconSize: {
    width: Device.getSize(30),
    height: Device.getSize(30),
  },
  largeIconSize: {
    width: Device.getSize(50),
    height: Device.getSize(50),
  },
  LargeIconSize2: {
    width: Device.width * 0.45,
    height: Device.width * 0.45
  },
  LargeIconSize3: {
    width: Device.getSize(380)
  },
  LargeIconSize4: {
    width: Device.getSize(50),
    marginLeft: Device.getSize(7)
  },
  LargeIconSize5: {
    width: Device.getSize(90)
  },
  LargeIconSize6: {
    width: Device.getSize(210)
  },
  LargeIconSize7: {
    width: Config.isTss || Config.isTgk ? Device.getSize(140) : Config.isJjk ? Device.getSize(180): Device.getSize(140)
  },
  LargeIconSize8: {
    width: Device.getSize(20),
    marginLeft: Device.getSize(7)
  },
  largeIconBg: {
    width: Device.getSize(135),
    height: Device.getSize(135),
  },
  largeIconBg2: {
    width: Device.getSize(135),
    height: Device.getSize(55),
  },
  imageSizeLandscape: {
    width: Config.imageSizeLandscape.width,
    height: Config.imageSizeLandscape.height,
  },
  iconRight: {
    marginRight: Device.getSize(12),
  },
  containerListRow: {
    paddingHorizontal: Device.getSize(12),
    paddingVertical: Device.getSize(20),
    borderBottomColor: '#33363D',
    borderStyle: 'solid',
    borderBottomWidth: 1,
  },

  blockLeftHalfMargin: {
    flex: 1,
    marginRight: Device.getSize(1),
    alignSelf: 'stretch',
    // borderStyle: 'solid',
    // borderRightColor: Colors.white,
    // borderRightWidth: Device.getSize(2),
  },
  blockRightHalfMargin: {
    flex: 1,
    marginLeft: Device.getSize(1),
    alignSelf: 'stretch',
    // borderStyle: 'solid',
    // borderLeftColor: Colors.white,
  },
  textCenter: {
    textAlign: 'center'
  },
  textMiddle: {
    paddingTop: Device.getSize(4)
  },
  relative: {
    position: 'relative'
  },
  map: {
    width: Device.width,
    height: Device.isSmallScreen ? Device.getSize(170) : Device.getSize(220),
  },
  seperatorGrey: {
    backgroundColor:  '#EEEEEE',
    height: Device.getSize(1),
    alignSelf: 'stretch',
  },
  seperatorBlack: {
    backgroundColor:  Colors.black,
    height: Device.getSize(1),
    alignSelf: 'stretch',
  },
  seperatorWhite: {
    backgroundColor:  Colors.whiteTransparent,
    height: Device.getSize(1),
    alignSelf: 'stretch',
  },
  imgNavBar: {
    width: Device.width,
    position: 'absolute',
    top: 0,
    left: 0,
    height:  Config.heightNavBar
  },
  transparent: {
    opacity: 0
  },
  heightHalfWidth: {
    height: Device.width/2
  },
  sectionQuantity: {
    alignSelf: 'stretch',
    paddingHorizontal: Device.getSize(10)
  },
  sectionRowBig: {
    minHeight: Device.getSize(100)
  },
  textSelection: {
    marginTop: Device.getSize(4),
    marginRight: Device.getSize(10)
  },
  absoluteLeftTop: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  absoluteRightTop: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  seperatorBigBlack: {
    alignSelf: 'stretch',
    height: Device.getSize(15),
    backgroundColor: Colors.black,
  },
  touchableCancelCamera: {
    position: 'absolute',
    left: Device.getSize(20),
    bottom: Device.getSize(20),
  },



  /* === Custom Styles === */
  seperatorBigTransparent: {
    alignSelf: 'stretch',
    height: Device.getSize(15)
  },
  textWhite: {
    // color: 'white'
    color: 'black'
  },
  textBlack: {
    color: 'white'
    // color: 'black'
  },
  leftSpace15: {
    marginLeft: 15
  },
  rightSpace15: {
    marginRight: 15
  },
  font15: {
    fontSize: Device.getSize(15)
  },
  font18: {
    fontSize: Device.getSize(18)
  },
  shadow: {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowRadius: 5,
    shadowOpacity: 0.7
  },
  qrContainer: {
    width: Device.getSize(300),
    height: Device.getSize(280),
    paddingVertical: Device.getSize(10),
    paddingHorizontal: Device.getSize(15),
    backgroundColor: Colors.white,
    marginBottom: Device.getSize(15)
  },
  orderInfo: {
    paddingTop: Device.getSize(5),
    paddingBottom: Device.getSize(5),
  },
  customBtn: {
    borderStyle: 'solid',
    borderRadius: Device.getSize(10),
    width: Device.width * 0.8,
  },
});

export default StyleHelper;
