import React from 'react';
import {
  Text,
  View,
} from 'react-native';

import styleHelper from '../Styles/StyleHelper';
import ThemeStyles from '../Styles/ThemeStyles';

import ImageBackground from '../Components/ImageBackground';

export default class Lost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return(
    <ImageBackground>
        <View style={[styleHelper.containerTopFit, styleHelper.flexCenter]}>
            <Text style={[ThemeStyles.secondaryFontColor(), styleHelper.font16, styleHelper.fontBold, styleHelper.flexCenter]}>
                Scan QR again to start ordering
            </Text>
        </View>
    </ImageBackground>
    );
  }
}
