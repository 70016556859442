import React from 'react';
import {Text, Image, View, TouchableOpacity} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';

import styleHelper from '../Styles/StyleHelper';
import ThemeStyles from '../Styles/ThemeStyles';

import Services from '../Utils/Services';

import OrderStore from '../Stores/OrderStore';
import OrderActions from '../Actions/OrderActions';

export default class RadioModifierGroup extends React.Component {
    constructor(props) {
        super(props);
        this.onPress = this.onPress.bind(this);

		this.state = {
            variants: OrderStore.getSelectedProduct().product_variant_ids,
        }
	}

	onPress(position, variant) {
		let variants = this.state.variants;
		variants[position].isAdded = true;
		OrderActions.selectVariant(variant);

		this.setState({
			variants: variants,
		})
	}

	render(){
		if(!this.state.variants[0]){
			return null;
		}
		return(
			<View>
				{
					this.state.variants.map((variant, i)=>{
						if(i%2===0){
							return(
								<View style={styleHelper.flexRow} key={i}>
									<TouchableRadio
										selected={variant.isAdded}
										data={variant}
										position={i}
										onPress={this.onPress}/>
									{
										(i+1<this.state.variants.length) &&
										<TouchableRadio
											selected={this.state.variants[i+1].isAdded}
											data={this.state.variants[i+1]}
											position={i+1}
											onPress={this.onPress}/>
									}

								</View>
							)
						}
					})
				}
			</View>
		)
	}
}

const TouchableRadio = (props)=>{
	let renderRadio = (selected)=>{
		if(selected === true){
			return(
                <Ionicons name="radio-button-on" size={Device.getSize(25)} color={ThemeStyles.iconColor()} />
			)
		}

		return(
            <Ionicons name="radio-button-off" size={Device.getSize(25)} color={ThemeStyles.iconColor()} />
		)

	}

	return(
		<TouchableOpacity
			activeOpacity={1}
			onPress={()=>props.onPress(props.position, props.data)}
			style={[styleHelper.flexRow, styleHelper.containerTiny, styleHelper.flex1]}>

			{renderRadio(props.selected)}

			<View style={[styleHelper.flexColumn, styleHelper.containerHorizontalSmall]}>
				<Text style={[styleHelper.fontHelveticaNeueRegular, styleHelper.font12]}>
					{props.data.attribute_value_ids[0].name}
				</Text>
				<Text style={[styleHelper.fontHelveticaNeueRegular, styleHelper.font10]}>
					+{Services.formatPrice(props.data.lst_price)}
				</Text>
			</View>
		</TouchableOpacity>
	)
}
