import React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';

import styleHelper from '../Styles/StyleHelper';
import ThemeStyles from '../Styles/ThemeStyles';

import IconPlus from './IconPlus';
import IconMinus from './IconMinus';

import Device from '../Utils/Device';
import Services from '../Utils/Services';

import StoreStore from '../Stores/StoreStore';
import OrderStore from '../Stores/OrderStore';

import OrderActions from '../Actions/OrderActions';

export default class Modifier extends React.Component {
    constructor(props) {
        super(props);

        this.modifier = this.modifier.bind(this);
        this.renderPrice = this.renderPrice.bind(this);
        this.renderAdd = this.renderAdd.bind(this);
        this.updateModifierValid = this.updateModifierValid.bind(this);
        this.updateModifier = this.updateModifier.bind(this);
        this.reselectModifier = this.reselectModifier.bind(this);
        this.deselectModifier = this.deselectModifier.bind(this);
    }

    updateModifierValid(index) {
        let modifierGroupsValid = this.props.modifierGroupsValid;
        modifierGroupsValid[index] = OrderStore.checkModifierGroupsValid()[index];

        this.props.onModifierChange(modifierGroupsValid);
    }

    updateModifier(modifier, modifierGroup, index) {
        OrderActions.selectModifier(modifier);
        this.updateModifierValid(index);

        // show certain modifierGroup
        this.props.toggleCustomModifiers(modifierGroup, false);
    }

    reselectModifier(modifier, modifierGroup, index) {
        OrderActions.selectModifierV2(modifier, modifierGroup);
        this.updateModifierValid(index);
    }

    deselectModifier(modifier, modifierGroup, index) {
        OrderActions.deselectModifier(modifier);
        this.updateModifierValid(index);

        // hide certain modifierGroup
        this.props.toggleCustomModifiers(modifierGroup, true);
    }

    renderAdd(modifier, modifierGroup, index) {
        // console.log(modifierGroup)
        /**
         * Selected
         * Max selection is 1
         */
        if (modifier.max_qty == 1 && modifier.quantity > 0) {
            if (modifier.pre_selected) {
                return (
                    <TouchableRadio
                        disabled={true}
                        selected={true} />
                )
            } else {
                return (
                    <TouchableRadio
                        selected={true}
                        modifier={modifier}
                        modifierGroup={modifierGroup}
                        index={index}
                        onPress={this.deselectModifier} />
                )
            }
        } else {
            /**
             * Not Selected, OR Selected but max qty not reached
             * Max qty of modifier group not reached
             */
            if (modifierGroup.numberSelectedModifier < modifierGroup.max_modifier && modifier.quantity < modifier.max_qty) {
                if (modifier.max_qty > 1) {
                    //Render quantity box
                    return (
                        <BoxQuantity
                            onPressMinus={() => {
                                OrderActions.deselectModifier(modifier);
                                this.updateModifierValid(index);
                            }}
                            onPressPlus={() => {
                                OrderActions.selectModifier(modifier);
                                this.updateModifierValid(index);
                            }}
                            modifier={modifier} />
                    )
                } else {
                    return (
                        <TouchableRadio
                            selected={false}
                            modifier={modifier}
                            modifierGroup={modifierGroup}
                            index={index}
                            onPress={this.updateModifier} />
                    )
                }
                /**
                 * Selected but max qty not reached
                 * Max qty or modifier group reached
                 * else chunk below almost imposible to trigger
                 */
            } else { // not selected, 
                if (modifier.max_qty > 1) {
                    //Render quantity box
                    return (
                        <BoxQuantity
                            onPressMinus={() => {
                                OrderActions.deselectModifier(modifier)
                                this.updateModifierValid(index);
                            }}
                            disabled={true}
                            modifier={modifier} />
                    )
                } else { // almost imposible to trigger
                    return (
                        <TouchableRadio
                            selected={false}
                            modifier={modifier}
                            modifierGroup={modifierGroup}
                            index={index}
                            onPress={this.reselectModifier} />
                    )
                }
            }
        }
    }

    renderPrice(price) {
        if (price > 0) {
            return (
                <Text style={[styleHelper.fontGrey, styleHelper.fontHelveticaNeueRegular, styleHelper.font10, styleHelper.containerHorizontalSmall]}>
                    (+{Services.formatPrice(price)})
                </Text>
            )
        }
    }

    modifier(modifierGroup, index) {
        return (
            <View>
                {
                    modifierGroup.product_modifier_ids.map((modifier, i) => (
                        <View key={i} style={[styleHelper.flexRowSpaceBetweenAlignTop, styleHelper.containerVerticalSmall2]}>
                            <View style={styleHelper.flex1}>
                                <Text style={[styleHelper.fontHelveticaNeueRegular, styleHelper.font12, styleHelper.DarkGreenFont, modifier.quantity > 0 && styleHelper.DarkFont]}>
                                    {modifier.product_id.name}
                                </Text>
                            </View>

                            <View style={styleHelper.flexRowSpaceBetween}>
                                {this.renderPrice(modifier.price)}
                                {this.renderAdd(modifier, modifierGroup, index)}
                            </View>

                        </View>
                    ))
                }
            </View>
        )
    }

    render() {
        let modifierGroups = [];
        let modifiers = [];

        if (this.props.modifierGroups.length === 0) {
            return null;
        } else {
            // oos modifier
            let isOos = false;
            let isDisabled = false;
            modifierGroups = [];
            this.props.modifierGroups.map((modifierGroup, i) => {
                if (modifierGroup.available_in_store) {
                    isOos = false;
                    isDisabled = false;
                    modifiers = [];
                    modifierGroup.product_modifier_ids.map((modifier, i) => {
                        isOos = modifier.product_id.oos_store.find(x => x.id === StoreStore.getSelectedStore().id);
                        if (modifier.product_id.disabled_store) {
                            isDisabled = modifier.product_id.disabled_store.find(x => x.id === StoreStore.getSelectedStore().id);
                        }

                        if (!isOos && modifier.available_in_store && !isDisabled) {
                            modifiers.push(modifier);
                        }
                    });
                    modifierGroup.product_modifier_ids = modifiers;
                    modifierGroups.push(modifierGroup);
                }
            });
        }

        return (
            <View>
                {
                    modifierGroups.map((modifierGroup, i) => (
                        !modifierGroup.hide ?
                            (
                                <View style={styleHelper.containerVerticalNormal} key={i}>
                                    <View style={[styleHelper.flexRow]}>
                                        {
                                            !this.props.modifierGroupsValid[i] &&
                                            <View style={[styleHelper.flexCenter, { borderRadius: Device.getSize(20), backgroundColor: '#F00', width: Device.getSize(15), height: Device.getSize(15), marginRight: Device.getSize(5), padding: Device.getSize(5) }]}>
                                                <Text style={[styleHelper.fontWhite, styleHelper.font12, styleHelper.fontHelveticaNeueRegular]}>
                                                    !
                                                </Text>
                                            </View>
                                        }

                                        <Text style={[styleHelper.fontHelveticaNeueBold, styleHelper.font12, styleHelper.containerVerticalTiny, styleHelper.DarkGreenFont, !this.props.modifierGroupsValid[i] && styleHelper.fontRed]}
                                            ref={(ref) => {
                                                this.props.modifierNodes.set(i, ref);
                                            }}>
                                            {modifierGroup.name}
                                        </Text>
                                    </View>

                                    <View style={[styleHelper.borderBottomWhiteTransparent, styleHelper.flexRow]}>
                                        <Text style={[styleHelper.fontHelveticaNeue, styleHelper.font11, styleHelper.fontBlackSecond, styleHelper.containerVerticalTiny]}>
                                            {modifierGroup.description}
                                        </Text>
                                    </View>

                                    {this.modifier(modifierGroup, i)}
                                </View>
                            ) : <View key={i} />
                    ))
                }
            </View>
        )
    }
}

const BoxQuantity = (props) => {
    return (
        <View style={styleHelper.flexRowSpaceBetween}>
            <IconMinus onPress={props.onPressMinus} />
            <View style={[styleHelper.inputBorderRadius, styleHelper.bgWhite, styleHelper.containerNumber]}>
                <Text style={[styleHelper.fontHelveticaNeueRegular, styleHelper.fontBlack, styleHelper.font14, styleHelper.textCenter]}>
                    {props.modifier.quantity}
                </Text>
            </View>
            <IconPlus onPress={props.onPressPlus} disabled={props.disabled} />
        </View>
    )
}

const TouchableRadio = (props) => {
    let renderRadio = (selected) => {
        if (selected === true) {
            return (
                <Ionicons name="radio-button-on" size={Device.getSize(25)} color={ThemeStyles.iconColor()} />
            )
        }

        return (
            <Ionicons name="radio-button-off" size={Device.getSize(25)} color={ThemeStyles.iconColor()} />
        )

    }

    if (props.disabled) {
        return (
            <View>
                {renderRadio(props.selected)}
            </View>
        )
    }

    return (
        <TouchableOpacity
            activeOpacity={1}
            onPress={() => props.onPress(props.modifier, props.modifierGroup, props.index)}
        >

            {renderRadio(props.selected)}

        </TouchableOpacity>
    )
}

