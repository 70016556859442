import React from 'react';
import { Text, View, Image, TouchableOpacity } from 'react-native';

// import * as Navigation from '../Routes/Routing';

import styleHelper from '../Styles/StyleHelper';

import Config from '../Utils/Config';
import Device from '../Utils/Device';

import OrderStore from '../Stores/OrderStore';
import StoreStore from '../Stores/StoreStore';
// import CartStore from '../Stores/CartStore';

class OrderInfo extends React.Component {
  render() {
    return (
      <View style={[styleHelper.orderInfo]}>
        <View style={[
          styleHelper.containerSmall,
          styleHelper.flexColumn,
          styleHelper.flexCenter
        ]}>

          <Text
            style={[
              styleHelper.fontRegular,
              styleHelper.fontBlack,
              styleHelper.font22,
              styleHelper.flex1,
            ]}>
            {StoreStore.getTableNo()}
          </Text>
        </View>
      </View>
    );
  }
}

export default OrderInfo;
