import React from 'react';
import {View, Image} from 'react-native';

import styleHelper from '../Styles/StyleHelper';

import Device from '../Utils/Device';
import Services from '../Utils/Services';

export default class ImageProduct extends React.Component {
    constructor(props) {
        super(props);
        this.getImageSize = this.getImageSize.bind(this);
        this.state = {
            imageHeight: this.props.height || 1,
            imageWidth: this.props.width || 1,
            uri: Services.getImageURL(this.props.imageID, 'product')
        };
    }
  
  componentDidMount() {
    if(!this.props.height){
      Services.onFocusCallback(()=>{
        this.getImageSize();
      })
    }
  }

  getImageSize() {
    Image.getSize(Services.getImageURL(this.props.imageID, 'product'), (imageWidth, imageHeight) => {
      let scaledImage = Services.getImageScaledSize(imageWidth, imageHeight, Device.width);
      this.setState({
        imageWidth: scaledImage.width,
        imageHeight: scaledImage.height
      });
    });
  }

  render(){
    if(this.props.imageID) {
      return(
        <Image
            source={{
                uri: this.state.uri
            }}
            resizeMode={'contain'}
            style={[{flex: 2, marginTop: Device.getSize(5), marginRight: Device.getSize(5), width: this.state.imageWidth, height: this.state.imageHeight}]} />
      )
    }

    return(
      <View />
    )

  }
}