import React from 'react';
import {ImageBackground, View} from 'react-native';

import styleHelper from '../Styles/StyleHelper';

const ImageBg = (props)=>{
    let uri = require('../Img/TSS_Background.jpg');

	return(
		// <ImageBackground
		// 	source={uri}
		// 	resizeMode={'cover'}
		// 	style={[styleHelper.backgroundImage, props.style]}>
		// 	{props.children}
		// </ImageBackground>
		<View
			style={[styleHelper.backgroundImage, props.style]}>
		 	{props.children}
		</View>
	)
}

export default ImageBg