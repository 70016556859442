import * as React from 'react';
import {
  createStackNavigator,
  // CardStyleInterpolators,
} from '@react-navigation/stack';

import Splash from '../Screens/Splash';
import Home from '../Screens/Home';
import Cart from '../Screens/Cart';
import Confirmation from '../Screens/Confirmation';
import Lost from '../Screens/Lost';
import Welcome from '../Screens/Welcome';
import MyOrder from '../Screens/MyOrder';
import Expired from '../Screens/Expired';
import Offset from '../Screens/Offset';

const Stack = createStackNavigator();

const ParentStack = () => (
  <Stack.Navigator
    initialRouteName="Splash"
    screenOptions={{
      gestureEnabled: true,
      gestureDirection: 'horizontal',
      // cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
    }}>
    <Stack.Screen
      name="Splash"
      component={Splash}
      options={() => ({
        headerShown: false,
      })}
    />
    <Stack.Screen
      name="Home"
      component={Home}
      options={() => ({
        headerShown: false,
      })}
    />
    <Stack.Screen
      name="Cart"
      component={Cart}
      options={() => ({
        headerShown: false,
      })}
    />
    <Stack.Screen
      name="Confirmation"
      component={Confirmation}
      options={() => ({
        headerShown: false,
      })}
    />
    <Stack.Screen
      name="Lost"
      component={Lost}
      options={() => ({
        headerShown: false,
      })}
    />
    <Stack.Screen
      name="Welcome"
      component={Welcome}
      options={() => ({
        headerShown: false,
      })}
    />
    <Stack.Screen
      name="MyOrder"
      component={MyOrder}
      options={() => ({
        headerShown: false,
      })}
    />
    <Stack.Screen
      name="Expired"
      component={Expired}
      options={() => ({
        headerShown: false,
      })}
    />
    <Stack.Screen
      name="Offset"
      component={Offset}
      options={() => ({
        headerShown: false,
      })}
    />
    </Stack.Navigator>
);

export default ParentStack;
